/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
import { atom, selector } from 'recoil';
import { getDocs, query, where, collection } from 'firebase/firestore';
import { firestore } from '../firebase';

const localStorageEffect =
  (project, key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(project);
    if (savedValue != null) {
      setSelf(
        JSON.parse(savedValue)[key] || {
          value: key === 'CurrentDate' ? '' : [],
          display: ''
        }
      );
    }

    onSet((newValue) => {
      if (typeof newValue === 'undefined') {
        localStorage.removeItem(project);
      } else {
        const lsi = JSON.parse(localStorage.getItem(project));
        localStorage.setItem(
          project,
          JSON.stringify({
            ...lsi,
            [key]: newValue
          })
        );
      }
    });
  };

const SaveInitedOffline =
  () =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem('OfflineInited');
    // eslint-disable-next-line
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (typeof newValue === 'undefined') {
        localStorage.removeItem('OfflineInited');
      } else {
        localStorage.setItem('OfflineInited', JSON.stringify(newValue));
      }
    });
  };
export const _CurrentLocation = atom({
  key: 'CurrentLocation',
  default: {
    type: '',
    query: {
      zip: '',
      city: '',
      latitude: '',
      longitude: ''
    }
  }
});
export const _AppVersion = atom({
  key: 'AppVersion',
  default: '2.5.21'
});

export const _payrollEmployeeTransaction = atom({
  key: 'PayrollEmployeeTransaction',
  default: {
    weekEndDate: new Date().toJSON().split('T')[0],
    employeeList: ''
  }
});

export const _ServiceWorkerUpdate = atom({
  key: 'ServiceWorkerUpdate',
  default: false
});
export const _ShowChangelogModal = atom({
  key: 'ShowChangelogModal',
  default: false
});

export const _InitedOffline = atom({
  key: 'InitedOffline',
  default: { value: false, lastInited: new Date().toJSON() },
  effects_UNSTABLE: [SaveInitedOffline()]
});
export const _InitingOffline = atom({
  key: 'InitingOffline',
  default: false
});
export const _HeaderTitle = atom({
  key: 'HeaderTitle',
  default: 'Daily Planning'
});
export const _CurrentJobNumber = atom({
  key: 'CurrentJobNumber',
  default: { value: [], display: '' },
  effects_UNSTABLE: [localStorageEffect('DJR-KEY', 'CurrentJobNumber')]
});
export const _CurrentForeman = atom({
  key: 'CurrentForeman',
  default: { value: [], display: '' },
  effects_UNSTABLE: [localStorageEffect('DJR-KEY', 'CurrentForeman')]
});
export const _CurrentDate = atom({
  key: 'CurrentDate',
  default: { value: '', display: '' },
  effects_UNSTABLE: [localStorageEffect('DJR-KEY', 'CurrentDate')]
});
export const _MultiJobsSelected = atom({
  key: 'MultiJobsSelected',
  default: { ids: [], display: '' }
});
export const _AllDivTime = atom({
  key: 'AllDivTime',
  default: false
});
export const _AllSubJobTime = atom({
  key: 'AllSubJobTime',
  default: false
});
export const _OnlyThisJob = atom({
  key: 'OnlyThisJob',
  default: true
});
export const _Division = selector({
  key: 'Division',
  get: async ({ get }) => {
    const job = get(_CurrentJobNumber);
    if (job.value.length > 0) {
      const result = await getDocs(
        query(
          collection(firestore, 'ENT-Jobs'),
          where('jctdscid', '==', job.value[0])
        )
      );
      return result.docs[0].data().Division;
    }
    return '';
  }
});
export const _Job = selector({
  key: 'Job',
  get: async ({ get }) => {
    const job = get(_CurrentJobNumber);
    if (job.value.length > 0) {
      const result = await getDocs(
        query(
          collection(firestore, 'ENT-Jobs'),
          where('jctdscid', '==', job.value[0])
        )
      );
      return result.docs[0].data().JobNumber;
    }
    return '';
  }
});
