import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme, Grid, Modal, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { doc, setDoc, collection, query, where, onSnapshot } from 'firebase/firestore';
import { InputDate, InputSelect } from '@aldridge/aldg-data-components';
import dayjs from 'dayjs';
import { _CurrentJobNumber, _CurrentForeman } from '../../_Recoil/atoms';
import { UserContext } from '../../providers/UserProvider';
import { firestore } from '../../firebase';
import { basicStyles } from '../../theme';

const StartDayCopyForm = (props) => {
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const { closePopup, displayBoxOpen } = props;
  const [tasks, setTasks] = useState([]);
  const [entry, setEntry] = useState({
    data: {
      copyToDate: '',
      startDate: '',
      endDate: '',
      tasks: []
    }
  });

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...entry };
      changedRecord.data[id] = value;
      if (id.toLowerCase().includes('date')) {
        changedRecord.data[id] = dayjs(value).format('YYYY-MM-DD');
      }
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setEntry(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const copyToDateFunction = () => {
    const tasksToCopy = entry.data.tasks;

    const { copyToDate } = entry.data;
    if (copyToDate === '') {
      toast.error('Copy To Date is required. Please enter a proper date.');
      return;
    }
    if (tasksToCopy.length === 0) {
      toast.error('You must select at least one task to copy.');
      return;
    }
    try {
      for (let i = 0; i < tasksToCopy.length; i++) {
        const t = tasksToCopy[i];
        const task = tasks.filter((_tasks) => _tasks.id === t);
        if (task.length > 0) {
          task[0].id = '';
          task[0].data.EndDay = {
            CompletedProductionQuantity: '',
            PercentComplete: '',
            ObstacleCode: [],
            SubObstacleCode: [],
            HoursImpacted: '',
            PeopleImpacted: '',
            ReasonsForImpact: ''
          };
          task[0].data.Date = copyToDate;
          const docRef = doc(collection(firestore, 'Tasks'));
          task[0].id = docRef.id;
          setDoc(docRef, task[0]);
        }
      }
      toast.success('Form saved successfully!', { autoClose: 5000 });
      setEntry({
        data: {
          copyToDate: '',
          startDate: '',
          endDate: '',
          tasks: []
        }
      });
      closePopup();
    } catch (err) {
      toast.error('Error occured while saving form.', { autoClose: 5000 });
    }
  };

  useEffect(() => {
    let mounted = true;
    if (entry.data.startDate !== '') {
      const colRef = (() => {
        let col = collection(firestore, 'Tasks');
        col = query(
          col,
          where('data.JobNumber', '==', CurrentJobNumber.value[0])
        );
        col = query(col, where('data.Foreman', '==', CurrentForeman.value[0]));
        col = query(col, where('data.Date', '>=', entry.data.startDate));
        if (entry.data.endDate !== '') {
          col = query(col, where('data.Date', '<=', entry.data.endDate));
        }
        return col;
      })();
      onSnapshot(colRef, (res) => {
        const allNewJobs = [];
        res.forEach((d) => allNewJobs.push(d.data()));
        if (mounted) setTasks(allNewJobs);
      });
    }
    return () => (mounted = false);
  }, [entry.data.startDate, entry.data.endDate]);
  const customClasses = makeStyles(
    () => ({
      button: {
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center'
        },
        marginLeft: '8px',
        marginBottom: '16px'
      },
      line: {
        padding: '8px'
      }
    }),
    { index: 1 }
  )();
  const classes = basicStyles();
  const wbsDescriptions = (task) => {
    const display = [];
    if ((task.data.Level1Display?.trim() || '') !== '') {
      display.push(task.data.Level1Display);
    }
    if ((task.data.Level2Display?.trim() || '') !== '') {
      display.push(task.data.Level2Display);
    }
    if ((task.data.Level3Display?.trim() || '') !== '') {
      display.push(task.data.Level3Display);
    }
    if ((task.data.Level4Display?.trim() || '') !== '') {
      display.push(task.data.Level4Display);
    }
    return display.join(' / ');
  };

  console.log(entry);
  return (
    <Grid container spacing={2}>
      <Modal
        open={displayBoxOpen}
        onClose={() => closePopup(false)}
        className={classes.modal}
      >
        <div className={classes.modalPaper} style={{ width: '50%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '1rem'
            }}
          >
            <Grid container>
              <h4
                style={{
                  textDecoration: 'underline',
                  position: 'relative',
                  flex: '1 1 auto',
                  padding: '0px',
                  paddingTop: '8px'
                }}
              >
                Copy Tasks
              </h4>
              <Grid item xs={12} className={customClasses.line}>
                <InputDate
                  type='date'
                  label='Start Date'
                  name='startDate'
                  onChange={onChange}
                  value={entry.data.startDate}
                />
              </Grid>
              <Grid item xs={12} className={customClasses.line}>
                <InputDate
                  type='date'
                  label='End Date'
                  name='endDate'
                  onChange={onChange}
                  value={entry.data.endDate}
                />
              </Grid>
              <Grid item xs={12} className={customClasses.line}>
                <InputSelect
                  label='P.O.D.s'
                  name='tasks'
                  onChange={onChange}
                  value={entry.data.tasks || []}
                  options={tasks
                    .map((copyTasks) => ({
                      value: copyTasks.id,
                      label: `${copyTasks.data.Date} - ${typeof copyTasks.data.Level1 !== 'undefined' &&
                        copyTasks.data.Level1 !== ''
                        ? wbsDescriptions(copyTasks)
                        : copyTasks.data.Task
                        }`
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  multiple
                />
              </Grid>
              <Grid item xs={12} className={customClasses.line}>
                <InputDate
                  type='date'
                  label='Copy To Date'
                  name='copyToDate'
                  onChange={onChange}
                  value={entry.data.copyToDate}
                />
              </Grid>
              <Grid
                container
                className={customClasses.line}
                style={{ marginLeft: '-9px' }}
              >
                <Button
                  className={customClasses.button}
                  variant='contained'
                  color='primary'
                  onClick={copyToDateFunction}
                >
                  Copy To Date
                </Button>
                <Button
                  variant='contained'
                  color='error'
                  style={{ marginLeft: '8px' }}
                  className={customClasses.button}
                  onClick={closePopup}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
    </Grid>
  );
};

StartDayCopyForm.propTypes = {
  closePopup: PropTypes.func,
  displayBoxOpen: PropTypes.bool
};
StartDayCopyForm.defaultProps = {
  closePopup: () => { },
  displayBoxOpen: false
};

export default StartDayCopyForm;