import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import {
  InputAutocomplete,
  InputSelect,
  InputNumber,
  InputDate,
  InputCheckbox
} from '@aldridge/aldg-data-components';
import {
  setDoc,
  doc,
  collection,
  getDoc,
  orderBy,
  onSnapshot,
  query
} from 'firebase/firestore';
import { firestore, firebaseConfig } from '../../firebase';
import { UserContext } from '../../providers/UserProvider';
import { weekEndDate } from '../../utils/dateFunctions';

const AccountingModalRecord = (props) => {
  const { user } = useContext(UserContext);
  const [loadedEmp, setLoadedEmp] = useState({ flag: false, value: undefined });
  const {
    currentJobNumber,
    currentDate,
    currentForeman,
    closePopup,
    record,
    employee,
    getRecords
  } = props;
  const [PerDiems, setPerDiems] = useState([]);
  const [entry, setEntry] = useState({
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      Crew: '',
      Date: currentDate,
      WeekEndDate: weekEndDate(currentDate),
      Doubletime: '',
      ExemptFromCertifiedPayroll: '',
      Holiday: '',
      JCTDSCID: currentJobNumber,
      JobDisplay: currentJobNumber,
      LessThan8HoursVerified: '',
      NoWorkRecorded: '',
      Overtime: '',
      PRTUNMID: '',
      PerDiem: '',
      Employee: '',
      EmployeeDisplay: '',
      Layoff: '',
      Person: '',
      PersonDisplay: '',
      PersonJobPiccDisplay: '',
      Picc: '',
      PiccDisplay: '',
      PreFab: user.prefab,
      PayItem: '',
      PayItemDisplay: '',
      Regular: '',
      Shift: '',
      SickLeave: '',
      SickLeaveType: '',
      Union: '',
      UnionDisplay: '',
      Vacation: ''
    }
  });

  useEffect(() => {
    let mounted = true;
    if (typeof record !== 'undefined') {
      if (entry.data.Employee !== '' && typeof employee !== 'undefined') {
        const uRecord = { ...entry };
        uRecord.data.Employee = employee;
        if (mounted) setEntry(uRecord);
      } else if (mounted) {
        setEntry(record);
      }
    }
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    let mounted = true;
    const pds = [];
    const q = query(collection(firestore, 'PerDiems'), orderBy('order'));
    onSnapshot(q, (res) => {
      res.forEach((d) => {
        pds.push(d.data());
      });
      if (mounted) setPerDiems(pds);
    });
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (loadedEmp.flag && typeof loadedEmp.value !== 'undefined') {
      const uEntry = { ...entry };
      uEntry.data.Crew = loadedEmp.value;
      if (mounted) setEntry(uEntry);
    }
    return () => (mounted = false);
  }, [loadedEmp]);
  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...entry };
      changedRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setEntry(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };
  const close = () => {
    setEntry({
      id: '',
      CreatedBy: '',
      CreatedDate: '',
      ModifiedBy: '',
      ModifiedDate: '',
      data: {
        Crew: '',
        Date: currentDate,
        WeekEndDate: weekEndDate(currentDate),
        Doubletime: '',
        ExemptFromCertifiedPayroll: '',
        Holiday: '',
        JCTDSCID: currentJobNumber,
        JobDisplay: currentJobNumber,
        Layoff: '',
        LessThan8HoursVerified: '',
        NoWorkRecorded: '',
        Overtime: '',
        PRTUNMID: '',
        PerDiem: '',
        Employee: '',
        EmployeeDisplay: '',
        Person: '',
        PersonDisplay: '',
        PersonJobPiccDisplay: '',
        Picc: '',
        PiccDisplay: '',
        PreFab: user.prefab,
        PayItem: '',
        PayItemDisplay: '',
        Regular: '',
        Shift: '',
        SickLeave: '',
        SickLeaveType: '',
        Union: '',
        UnionDisplay: '',
        Vacation: ''
      }
    });
    closePopup();
  };

  const isValidRecord = () => {
    let valid = true;
    const err = [];
    let perDiemRecord = false;
    let noWorkRecord = false;
    if (entry.data.PerDiem !== '') {
      perDiemRecord = true;
    }
    if (entry.data.NoWorkRecorded === 'true') {
      noWorkRecord = true;
    }
    if (entry.data.Crew.length === 0) {
      valid = false;
      err.push('You must select an Employee to Enter Time for.');
    }
    if (entry.data.Picc.length > 0 && noWorkRecord) {
      valid = false;
      err.push('You cannot select a PICC for No Work Recorded.');
    }
    if (entry.data.Picc.length === 0 && !perDiemRecord && !noWorkRecord) {
      valid = false;
      err.push('You must select a PICC to Enter Time for.');
    }
    if (entry.data.Shift === '' && !perDiemRecord && !noWorkRecord) {
      valid = false;
      err.push('You must select a Shift to Enter Time for.');
    }
    if (entry.data.Shift !== '' && noWorkRecord) {
      valid = false;
      err.push('You cannot select a Shift for No Work Recorded.');
    }
    if (
      entry.data.Regular === '' &&
      entry.data.Overtime === '' &&
      entry.data.Doubletime === '' &&
      entry.data.Holiday === '' &&
      entry.data.Vacation === '' &&
      entry.data.SickLeave === '' &&
      !perDiemRecord &&
      !noWorkRecord
    ) {
      valid = false;
      err.push('You must enter Hours to Enter Time for.');
    }
    if (
      (entry.data.Regular !== '' ||
        entry.data.Overtime !== '' ||
        entry.data.Doubletime !== '' ||
        entry.data.Holiday !== '' ||
        entry.data.Vacation !== '' ||
        entry.data.SickLeave !== '') &&
      noWorkRecord
    ) {
      valid = false;
      err.push('You cannot enter Hours for No Work Recorded.');
    }
    return { valid, err: err.join(' ') };
  };
  const createTimeRecord = async () => {
    const cEntry = { ...entry };
    const validRecord = isValidRecord();
    if (validRecord.valid) {
      if (cEntry.id === '') {
        const EmployeeRecord = (
          await getDoc(doc(firestore, 'Crew', cEntry.data.Crew[0]))
        ).data();
        toast.success('Time Entered Succesfully.');

        // eslint-disable-next-line prefer-destructuring
        cEntry.data.Employee = EmployeeRecord.data.Employee[0];
        cEntry.data.EmployeeDisplay = EmployeeRecord.data.EmployeeDisplay;
        // eslint-disable-next-line prefer-destructuring
        cEntry.data.Union = EmployeeRecord.data.Union[0];
        cEntry.data.UnionDisplay = EmployeeRecord.data.UnionDisplay;

        cEntry.data.PersonJobPiccDisplay = `${EmployeeRecord.data.EmployeeDisplay}\n${currentJobNumber}\n${cEntry.data.PiccDisplay}`;
        const docID = doc(collection(firestore, 'TimeEntries'));
        cEntry.id = docID.id;
        getRecords(true);
        close();
      }
      setDoc(doc(firestore, 'TimeEntries', cEntry.id), cEntry, { merge: true });
      getRecords(true);
      close();
    } else {
      toast.error(validRecord.err);
    }
  };

  const whereClause = () => {
    if (currentForeman > 0) return [['data.Foreman', '==', currentForeman]];
    if (entry.data.Crew !== '') return [['id', '==', entry.data.Crew]];

    return [['data.JobNumber', '==', currentJobNumber]];
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div>
            {entry?.data?.id === '' || typeof entry?.data?.id === 'undefined'
              ? 'Entering'
              : 'Modifying'}{' '}
            Time for{' '}
            <span style={{ fontWeight: 'bold' }}>
              {entry?.data?.JobDisplay}
            </span>{' '}
            on <span style={{ fontWeight: 'bold' }}>{currentDate}</span>...
          </div>
        </Grid>
        <Grid item xs={12}>
          <InputDate
            name='Date'
            label='Work Date'
            collection='TimeEntries'
            firestore={firestore}
            value={entry.data.Date}
            onChange={onChange}
            where={[['data.JobNumber', '==', currentJobNumber]]}
            valueKey='id'
            enterprise={false}
            disablePortal
          />
        </Grid>
        <Grid item xs={12}>
          <InputAutocomplete
            firebaseConfig={firebaseConfig}
            name='Crew'
            label='Employee'
            firestoreOptions={{
              method: 'onSnapshot',
              collection: 'Crew',
              where: whereClause(),
              orderBy: 'data.EmployeeDisplay',
              valueKey: 'id'
            }}
            value={
              typeof entry.data.Crew === 'string' && entry.data.Crew !== ''
                ? [entry.data.Crew]
                : entry.data.Crew
            }
            onChange={onChange}
            // optionDisplay={["option_EmployeeDisplay", '[ Union: ', 'option_UnionDisplay', ' ]']}
            optionDisplay={(opts) => {
              if (Object.keys(opts).length === 0) return '';

              return `${opts.data.EmployeeDisplay} [ Union: ${opts.data.UnionDisplay} ]`;
            }}
            filterDataset={(opts) => {
              if (typeof employee !== 'undefined') {
                const filteredEmps = opts.filter(
                  (e) => e.data.Employee[0] === employee
                );
                if (!loadedEmp.flag && filteredEmps.length === 1) {
                  setLoadedEmp({ flag: true, value: [filteredEmps[0].id] });
                }
                if (!loadedEmp) {
                  setLoadedEmp({ flag: true, value: undefined });
                }
                return filteredEmps;
              }
              const uniqueOpts = [];

              opts.forEach((opt) => {
                const exists = uniqueOpts.findIndex(
                  (u) =>
                    u.data.Union[0] === opt.data.Union[0] &&
                    u.data.Employee[0] === opt.data.Employee[0]
                );
                if (exists === -1) {
                  uniqueOpts.push(opt);
                }
              });
              return uniqueOpts;
            }}
            enterprise={false}
            disablePortal
          />
        </Grid>
        <Grid item xs={12}>
          <InputAutocomplete
            firebaseConfig={firebaseConfig}
            firestoreOptions={{
              method: 'get',
              valueKey: 'jctmstid',
              collection: 'ENT-Piccs',
              where: [['jctdscid', '==', entry.data.JCTDSCID]]
            }}
            name='Picc'
            label='Picc'
            value={
              typeof entry.data.Picc === 'string' && entry.data.Picc !== ''
                ? [entry.data.Picc]
                : entry.data.Picc
            }
            onChange={onChange}
            optionDisplay={(opts) => {
              if (typeof opts.jctdscid === 'undefined') {
                return '';
              }
              return `${opts.PICC} - ${opts.Description}`;
            }}
            enterprise
            disablePortal
          />
        </Grid>
        <Grid item xs={6}>
          <InputSelect
            name='Shift'
            label='Shift'
            value={entry.data.Shift || '1'}
            onChange={onChange}
            options={[
              { label: '1', value: '1', default: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' }
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <InputNumber
            name='Regular'
            label='Regular'
            value={entry.data.Regular || ''}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <InputNumber
            name='Overtime'
            label='Overtime'
            value={entry.data.Overtime || ''}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <InputNumber
            name='Doubletime'
            label='Doubletime'
            value={entry.data.Doubletime || ''}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <InputNumber
            name='Holiday'
            label='Holiday'
            value={entry.data.Holiday || ''}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <InputNumber
            name='Vacation'
            label='Vacation'
            value={entry.data.Vacation || ''}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <InputSelect
            name='SickLeaveType'
            label='Sick Leave Type'
            value={entry.data.SickLeaveType || ''}
            onChange={onChange}
            options={[
              { value: 'SL', label: 'California' },
              { value: 'S1', label: 'MD/DC' },
              { value: 'S2', label: 'IBEW 26 Sick Leave' }
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <InputNumber
            name='SickLeave'
            label='Sick Leave'
            value={entry.data.SickLeave || ''}
            onChange={onChange}
          />
        </Grid>
        <Grid item md={2} xs={4} style={{ whiteSpace: 'nowrap' }}>
          <InputSelect
            name='PerDiem'
            label='Per Diem'
            value={entry.data.PerDiem || ''}
            onChange={onChange}
            options={PerDiems}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <InputCheckbox
            checkboxes={[
              { value: 'true', label: 'Exempt' },
            ]}
            LabelProps={{
              label: '',
            }}
            name="ExemptFromCertifiedPayroll"
            onChange={onChange}
            value={entry.data.ExemptFromCertifiedPayroll || ''}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <InputCheckbox
            checkboxes={[
              { value: 'true', label: 'Layoff' },
            ]}
            LabelProps={{
              label: '',
            }}
            name="Layoff"
            onChange={onChange}
            value={entry.data.Layoff || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputCheckbox
            checkboxes={[
              { value: 'true', label: 'No work recorded' },
            ]}
            LabelProps={{
              label: '',
            }}
            name="NoWorkRecorded"
            onChange={onChange}
            value={entry.data.NoWorkRecorded || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent='flex-end'>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={createTimeRecord}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' color='error' onClick={close}>
                Close
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

AccountingModalRecord.propTypes = {
  closePopup: PropTypes.func,
  record: PropTypes.objectOf(PropTypes.any),
  employee: PropTypes.string,
  getRecords: PropTypes.func,
  currentDate: PropTypes.string,
  currentForeman: PropTypes.string,
  currentJobNumber: PropTypes.string
};
AccountingModalRecord.defaultProps = {
  closePopup: () => { },
  record: undefined,
  employee: undefined,
  getRecords: () => { },
  currentDate: undefined,
  currentForeman: undefined,
  currentJobNumber: undefined
};

export default AccountingModalRecord;
