/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react';
import { Button, Paper, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/pro-regular-svg-icons';
import { UserContext } from '../providers/UserProvider';
import { auth, signOut } from '../firebase';

const Unauthorized = () => {
  const user = useContext(UserContext);
  return (
    <Paper>
      <Grid container spacing={1} style={{ padding: '8px' }}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <FontAwesomeIcon size='8x' icon={faBan} color='red' />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Typography variant='h5' align='center'>
            You are currently unauthorized for this application with this account ({user.email}). Please contact the helpdesk if you should have
            access.
          </Typography>
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={3} />
        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <Button
            type='button'
            variant='contained'
            onClick={() => {
              signOut(auth);
            }}
          >
            Sign out
          </Button>
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </Paper>
  );
};
export default Unauthorized;
