const CompaniesColumns = [
  {
    field: 'CompanyNumber',
    headerName: 'Company Number',
    width: 150
  },
  {
    field: 'CompanyName',
    headerName: 'Company Name',
    width: 175
  }
];


export default CompaniesColumns;