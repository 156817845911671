/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, useMediaQuery, createTheme, Grid } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/pro-solid-svg-icons';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
  getDoc
} from 'firebase/firestore';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { firestore } from '../../firebase';
import { UserContext } from '../../providers/UserProvider';
import { icons } from '../../theme';

const UserList = (props) => {
  const { history } = props;
  const { user } = useContext(UserContext);
  const [Data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const GridApiRef = useGridApiRef();
  const iconClasses = icons();

  const STUBPAGE = 'Users';
  const STUBCOLLECTION = 'Users';

  const addNew = () => {
    history.push(`/${STUBPAGE}/new`);
  };
  const CellSelect = (cell) => {
    if (cell.field === '__check__') return;
    history.push(`/${STUBPAGE}/${cell.id}`);
  };
  useEffect(() => {
    let mounted = true;

    const fRef = query(collection(firestore, `${STUBCOLLECTION}`));
    onSnapshot(fRef, (res) => {
      const all = [];
      res.forEach((d) => all.push(d.data()));
      if (mounted) setData(all);
    });
    return () => (mounted = false);
  }, []);

  const _theme = createTheme();
  const matches = useMediaQuery(_theme.breakpoints.down('sm'));
  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const columns = [
    {
      field: 'FIELD',
      headerName: 'Date',
      valueGetter: (params) => params.row.name,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'FIELD1',
      headerName: 'Job',
      valueGetter: (params) => params.row.email,
      flex: getFlex(15),
      width: getWidth(300)
    }
  ];

  useEffect(() => {
    let mounted = true;
    if (mounted) setRows(Data);
    return () => (mounted = false);
  }, [Data]);

  const deleteRecords = () => {
    selection.forEach((s) => {
      deleteDoc(doc(firestore, `${STUBCOLLECTION}`, s));
    });
    setSelection([]);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <div style={{ paddingTop: '10px' }} />
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: matches ? '20px 0' : '10px 0',
            justifyContent: 'flex-end'
          }}
        >
          <div
            className={`${iconClasses.container} ${iconClasses.redContainer}`}
            style={{ margin: matches ? '0px -4px' : '0px 12px' }}
          >
            <Button
              onClick={deleteRecords}
              disableRipple
              className={iconClasses.buttonWrapper}
            >
              <FontAwesomeIcon
                icon={faTrash}
                className={iconClasses.icon}
                title='Delete Selected Records'
              />
            </Button>
          </div>
          <div
            className={`${iconClasses.container} ${iconClasses.addContainer}`}
            style={{ marginRight: '0px', marginLeft: matches ? '8px' : '12px' }}
          >
            <Button
              onClick={addNew}
              disableRipple
              className={iconClasses.buttonWrapper}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className={iconClasses.icon}
                onClick={addNew}
                title='Add New Equipment'
              />
            </Button>
          </div>
        </div>
        <AldgDataGrid
          autoHeight
          pagination
          pageSize={25}
          checkboxSelection
          accessSelections={setSelection}
          selectionModel={selection}
          onRowClick={CellSelect}
          getRowId={(r) => r.email}
          rows={rows}
          columns={columns}
          apiRef={GridApiRef}
        />
      </Grid>
    </Grid>
  );
};

UserList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};
UserList.defaultProps = {};

export default UserList;
