import React, { useState, useContext } from 'react';
import { Modal, Grid, Button, createTheme } from '@mui/material';
import { InputText, InputTextarea } from '@aldridge/aldg-data-components';
import { toast } from 'react-toastify';
import { OfflineContext } from '@aldridge/aldg-helpers';
import { httpsCallable } from 'firebase/functions';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faSave } from '@fortawesome/pro-regular-svg-icons';

import { functions } from '../firebase';

import { UserContext } from '../providers/UserProvider';

const SubmitHelpDeskTicket = () => {
  const [record, setRecord] = useState({
    subject: '',
    message: ''
  });
  const { user } = useContext(UserContext);
  const online = useContext(OfflineContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitHelpdeskTicket = httpsCallable(functions, 'SubmitIncident');
  const openModal = () => {
    setModalOpen(true);
  };
  const submitTicket = () => {
    if (record.subject !== '' && record.message !== '') {
      setLoading(true);
      submitHelpdeskTicket({
        subject: record.subject,
        message: record.message,
        sender: user.email
      })
        .then((res) => {
          if (res.data?.status?.toLowerCase() === 'success') {
            toast.success('Helpdesk Submitted!');
            setModalOpen(false);
          } else {
            toast.error(
              'There was an error submitting the ticket. Please try again later.'
            );
          }
          setLoading(false);
        })
        .catch((err) => {
          toast.error(`${err.message}`);
          setLoading(false);
        })
        .finally(() => {
          setModalOpen(false);
        });
    } else {
      toast.error(
        'You must fill out the Subject and the Details to submit a ticket.'
      );
    }
  };

  const t = createTheme();

  return (
    <>
      <Modal
        open={modalOpen}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            backgroundColor: t.palette.background.paper,
            border: '2px solid #000',
            boxShadow: t.shadows[5],
            outline: 'none',
            padding: t.spacing(1, 2),
            width: '50%'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '12px',
              flexDirection: 'column'
            }}
          >
            <Grid padding={2}>
              <InputText
                name='subject'
                label='Ticket Subject'
                value={record.subject}
                onChange={(e) => {
                  setRecord((prev) => ({ ...prev, subject: e }));
                }}
              />
            </Grid>
            <Grid padding={2}>
              <InputTextarea
                name='message'
                label='Ticket Details'
                value={record.message}
                onChange={(e) => setRecord((prev) => ({ ...prev, message: e }))}
                minRows={5}
              />
            </Grid>
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end'
            }}
          >
            {online ? (
              <ToolTipIconButton
                variant='contained'
                color='primary'
                onClick={submitTicket}
                label='Submit Helpdesk Ticket'
                icon={faSave}
                loading={loading}
              />
            ) : (
              <div style={{ color: 'red', fontWeight: 'bold' }}>
                You have no internet connection. Please try submitting a ticket
                later.
              </div>
            )}
            &nbsp;
            <Button
              variant='contained'
              color='error'
              onClick={() => setModalOpen(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      {online ? (
        <Button color='primary' onClick={openModal} variant='contained'>
          Contact Aldridge
        </Button>
      ) : (
        <div style={{ color: 'red', fontWeight: 'bold' }}>
          You have no internet connection. Please try submitting a ticket later.
        </div>
      )}
    </>
  );
};

export default SubmitHelpDeskTicket;
