import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  useMediaQuery,
} from '@mui/material';
import {
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import makeStyles from '@mui/styles/makeStyles';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import {
  deleteDoc,
  onSnapshot,
  setDoc,
  doc,
  collection,
  query,
  where,
  orderBy,
} from 'firebase/firestore';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { firestore } from '../../firebase';
import { _CurrentForeman, _CurrentJobNumber } from '../../_Recoil/atoms';

const AddedByAdmin = () => <span style={{ fontStyle: 'italic', color: 'rgb(63, 81, 181)' }}>Added By Administrator</span>;
const gridStyles = makeStyles(
  () => ({
    root: {
      '& .MuiDataGrid-footerContainer': {
        justifyContent: 'flex-start',
        marginLeft: '-12px'
      },
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: '#fff'
      },
      '& .MuiDataGrid-row:nth-child(odd)': {
        backgroundColor: '#efefef'
      },
      '& .MuiDataGrid-row:nth-child(even)': {
        backgroundColor: '#fff'
      }
    }
  }),
  { index: 1 }
);

const CrewList = (props) => {

  const [Crew, setCrew] = useState([]);
  const [JobCrew, setJobCrew] = useState([]);
  const { history } = props;
  const GridApiRef = useGridApiRef();
  const gridClasses = gridStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const mounted = useRef(null);
  const handleSelect = (c) => {
    if (c.field === '__check__') return;
    if (c.field === 'AddToCrew') return;
    if (c.row.NotInCrew) { toast.error('You cannot modify the crew members added by the project team.'); return; }
    if (
      CurrentForeman.value[0] === c.row.data.Foreman ||
      CurrentForeman.value.length === 0
    ) {
      history.push(`/crew/${c.id}`);
    } else {
      toast.info('This is not your crew member to modify.');
    }
  };
  const addNew = () => {
    if (CurrentJobNumber.value.length > 0 || CurrentForeman.value.length > 0)
      history.push(`/crew/new`);
    else
      toast.error(
        'You must select a Job or a Foreman before adding a Crew Member...'
      );
  };

  const getRecords = () => {
    const colRef = (() => {
      if (
        CurrentJobNumber.value.length > 0 &&
        CurrentForeman.value.length > 0
      ) {
        const crewWithJobAndNoForeman = query(
          collection(firestore, 'Crew'),
          where('data.Foreman', '==', ''),
          where('data.JobNumber', '==', CurrentJobNumber.value[0])
        );
        return crewWithJobAndNoForeman;
      }
      return undefined;
    })();

    const colJobRef = (() => {
      let col = query(collection(firestore, 'Crew'));

      if (
        CurrentJobNumber.value.length > 0 &&
        CurrentForeman.value.length === 0
      ) {
        const crewWithJobAndNoForeman = query(
          col,
          where('data.Foreman', '==', ''),
          where('data.JobNumber', '==', CurrentJobNumber.value[0])
        );
        col = query(crewWithJobAndNoForeman);
      }
      if (
        CurrentJobNumber.value.length === 0 &&
        CurrentForeman.value.length > 0
      ) {
        const crewWithNoJobAndForeman = query(
          col,
          where('data.Foreman', '==', CurrentForeman.value[0]),
          where('data.JobNumber', '==', '')
        );
        col = query(crewWithNoJobAndForeman);
      }
      if (
        CurrentJobNumber.value.length > 0 &&
        CurrentForeman.value.length > 0
      ) {
        const crewWithJobAndForeman = query(
          col,
          where('data.Foreman', '==', CurrentForeman.value[0]),
          where('data.JobNumber', '==', CurrentJobNumber.value[0])
        );
        col = query(crewWithJobAndForeman);
      }
      col = query(col, orderBy('data.EmployeeDisplay'));

      if (
        CurrentJobNumber.value.length === 0 &&
        CurrentForeman.value.length === 0
      ) {
        col = undefined;
      }
      return col;
    })();

    if (typeof colRef !== 'undefined') {
      onSnapshot(colRef, (snap) => {
        const rec = [];
        snap.forEach((d) => rec.push(d.data()));
        if (mounted.current) {
          setCrew(rec);
        }
      });
    }
    if (typeof colJobRef !== 'undefined') {
      onSnapshot(colJobRef, (snap) => {
        const rec = [];
        snap.forEach((d) => rec.push(d.data()));
        if (mounted.current) {
          setJobCrew(rec);
        }
      });
    } else if (mounted.current) {
      setJobCrew([]);
    }
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) getRecords();
    return () => (mounted.current = false);
  }, [CurrentForeman, CurrentJobNumber]);

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const addToOwnCrew = (member) => {
    if (CurrentForeman.value.length > 0) {
      const newRecord = { ...member };
      [newRecord.data.Foreman] = CurrentForeman.value;
      newRecord.data.ForemanDisplay = CurrentForeman.shortDisplay;
      delete newRecord.NotInCrew;
      const docRef = doc(collection(firestore, 'Crew'));
      newRecord.id = docRef.id;
      setDoc(docRef, newRecord);
      toast.success('Crew Member added!');
    } else {
      toast.error(
        'You must have a selected Foreman to add them to a permanent crew.'
      );
    }
  };
  const deleteRecords = () => {
    const selectedRows = Array.from(GridApiRef.current.getSelectedRows()?.entries());
    selectedRows.forEach((s) => {
      const [id] = s;
      if (Crew.filter(c => c.id === id).length === 0)
        deleteDoc(doc(firestore, 'Crew', id));
      else
        toast.error('Cannot delete Admin added Crew Member.');
    });
  };

  const columns = [
    {
      field: 'CrewMember',
      headerName: 'Name',
      valueGetter: (params) =>
        params.row.data.EmployeeDisplay,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Union',
      headerName: 'Union',
      valueGetter: (params) => params.row.data.UnionDisplay,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Foreman',
      headerName: 'Foreman',
      // eslint-disable-next-line react/no-unstable-nested-components
      renderCell: (params) => params.row.NotInCrew ? (<AddedByAdmin />) : `${params.row.data.ForemanDisplay}`,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'DefaultPerDiem',
      headerName: 'Automatic Per Diem',
      valueGetter: (params) => typeof params.row.data.DefaultPerDiem !== 'undefined' && params.row.data.DefaultPerDiem.length > 0 ? `$${params.row.data.DefaultPerDiem}` : '',
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'AddToCrew',
      headerName: 'Add to Crew',
      renderCell: (params) => params.row.NotInCrew ? (
        <ToolTipIconButton icon={faPlus} tooltipText='Add New Crew Member' shape='circle' onClick={(event) => { addToOwnCrew(params.row); event.preventDefault(); event.stopPropagation(); }} />
      ) : null,
      flex: getFlex(15),
      width: getWidth(300)
    }
  ];
  const mergeCrews = (crew1, crew2) => {
    const crews = [].concat(crew1);
    crew2.forEach(c => {
      const crewF = crews.filter(c2 => c2.data.Employee[0] === c.data.Employee[0] && c2.data.Union[0] === c.data.Union[0]);
      if (crewF.length === 0) {
        c.NotInCrew = true;
        crews.push(c);
      }
    });
    return crews;
  };
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '16px'
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: matches ? '20px 0' : '10px 0',
            justifyContent: 'flex-end'
          }}
        >
          <ToolTipIconButton icon={faTrash} color="error" tooltipText='Delete Selected Records' onClick={deleteRecords} />
          <ToolTipIconButton icon={faPlus} tooltipText='Add New Record' onClick={addNew} />
        </div>
      </div>
      <div style={{ height: '100%', width: '100%' }}>
        <AldgDataGrid
          autoHeight
          checkboxSelection
          isRowSelectable={(params) => !params.row.NotInCrew}
          onRowClick={handleSelect}
          className={gridClasses.root}
          rows={mergeCrews(JobCrew, Crew)}
          columns={columns}
          apiRef={GridApiRef}
        />
      </div>
    </>
  );
};
CrewList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};
CrewList.defaultProps = {};

export default CrewList;