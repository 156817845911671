/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilValue } from 'recoil';
// import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { Button, useMediaQuery, useTheme, Grid, Paper } from '@mui/material';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  Files,
  InputAutocomplete,
  InputNumber,
  InputText,
  InputTextarea,
  RepeatableList
} from '@aldridge/aldg-data-components';
import SignatureCanvas from 'react-signature-canvas';
import { onSnapshot, collection, query, where } from 'firebase/firestore';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { UserContext } from '../../providers/UserProvider';
import { config, firebaseConfig, firestore } from '../../firebase';
import { _CurrentDate, _CurrentJobNumber } from '../../_Recoil/atoms';

import { basicStyles } from '../../theme';
import { checkPOD } from '../../utils/checkLockout';

const DailyReportForm = (props) => {
  const { handleChange, record, setRecord, ForemanSigRef, CustomerSigRef } =
    props;
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const { user } = useContext(UserContext);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [equipment, setEquipment] = useState([]);

  const modifiedRecord = (cR) => {
    if (cR.CreatedDate === '') {
      cR.CreatedBy = user.email;
      cR.CreatedDate = new Date().toJSON();
    }
    cR.ModifiedBy = user.email;
    cR.ModifiedDate = new Date().toJSON();
    setRecord(cR);
  };

  const checkLockout = checkPOD(user, record);
  // const checkLockout = (() => {
  //   if (user && user.admin) return false;
  //   const lastDayToEdit = dayjs().subtract('5', 'day').format('YYYY-MM-DD');
  //   if (lastDayToEdit >= record.data.Date && record.data.Date !== '') {
  //     toast.info(
  //       `I'm sorry, you can no longer add/modify Tasks for "${record.data.Date}".`
  //     );
  //     return true;
  //   }
  //   return false;
  // })();

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const nameCanSplit = (n) => {
        if (n.split('.').length > 1) return [true, n.split('.')];
        return [false, n];
      };
      const [canSplit, id] =
        typeof name !== 'undefined'
          ? nameCanSplit(name)
          : nameCanSplit(event.target.name);
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      if (canSplit) {
        changedRecord.data[id[0]][id[1]] = value;
      } else {
        changedRecord.data[id] = value;
      }

      if (typeof displayFromTypeahead !== 'undefined') {
        if (canSplit) {
          changedRecord.data[id[0]][`${id[1]}Display`] = displayFromTypeahead;
        } else {
          changedRecord.data[`${id}Display`] = displayFromTypeahead;
        }
      }
      modifiedRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (CurrentJobNumber.value.length > 0 && mounted) {
      handleChange(
        CurrentJobNumber.value[0],
        'JobNumber',
        CurrentJobNumber.display
      );
    }
    return () => {
      mounted = false;
    };
  }, [CurrentJobNumber]);

  const classes = basicStyles();
  const updateSignature = (name, clear) => {
    const updatedRec = { ...record };
    const dataUrl =
      name === 'Foreman'
        ? ForemanSigRef.current.toDataURL()
        : CustomerSigRef.current.toDataURL();
    updatedRec.data[`${name}Signature`] = clear ? '' : dataUrl;
    setRecord(updatedRec);
  };
  const clearSignature = (name) => {
    if (name === 'Foreman') {
      ForemanSigRef.current.clear();
    } else {
      CustomerSigRef.current.clear();
    }
    updateSignature(name, true);
  };

  const subcontractorTransactions = {
    Subcontractor: [],
    TasksCompleted: '',
    NumberOfSubPersonnel: ''
  };
  const equipmentTransactions = {
    Equipment: [],
    Hours: ''
  };

  useEffect(() => {
    let mounted = true;
    if (CurrentJobNumber.value.length === 0 && mounted) {
      setEquipment([]);
      return;
    }
    const q = query(
      collection(firestore, 'Equipment'),
      where('data.JobNumber', '==', CurrentJobNumber.value[0])
    );
    onSnapshot(q, (res) => {
      const allEquipment = [];
      res.forEach((d) => allEquipment.push(d.data()));
      if (mounted) setEquipment(allEquipment);
    });

    // eslint-disable-next-line consistent-return
    return () => {
      mounted = false;
    };
  }, [CurrentJobNumber]);

  const AddAllEquipment = () => {
    const allEquipmentOnJob = [];
    const r = {
      CreatedBy: '',
      CreatedDate: '',
      ModifiedBy: '',
      ModifiedDate: '',
      Equipment: [],
      Hours: '',
      EquipmentDisplay: ''
    };

    for (let i = 0; i < equipment.length; i++) {
      const uR = JSON.parse(JSON.stringify(r));

      uR.Equipment = [equipment[i]?.id];
      uR.Hours = '';
      uR.EquipmentDisplay = `${
        equipment[i].data.EquipmentDisplay || 'No AE Number'
      } - ${equipment[i].data.Description}`;

      uR.CreatedBy = user.email;
      uR.CreatedDate = new Date().toJSON();
      uR.ModifiedBy = user.email;
      uR.ModifiedDate = new Date().toJSON();
      allEquipmentOnJob.push(uR);
    }
    const uRecord = { ...record };
    uRecord.data.Equipments = allEquipmentOnJob;

    setRecord(uRecord);
  };

  const onJobNumberChange = (event, name, displayFromTypeahead) => {
    handleChange(event[0], name, displayFromTypeahead.split(' [')[0]);
  };

  // const jobFilterClauses =
  //   user.IsAldridgeEmployee ||
  //   (user.IsAldridgeEmployee && !existsWithLength(user.JobAssignment))
  //     ? [['JobStatus', '==', 'Open']]
  //     : [
  //         ['JobStatus', '==', 'Open'],
  //         ['jctdscid', 'in', user.JobAssignment]
  //       ];
  return (
    <Paper className={classes.simplePrimaryPaper} variant='outlined'>
      <Grid container spacing={1} style={{ padding: '4px' }}>
        <Grid item xs={12}>
          <h2>{`Daily Job Report For ${CurrentDate.display}`}</h2>
        </Grid>
        <Grid item sm={4} xs={12}>
          <InputAutocomplete
            firebaseConfig={firebaseConfig}
            label='Job Number'
            value={[record.data.JobNumber] || []}
            onChange={onJobNumberChange}
            name='JobNumber'
            firestoreOptions={{
              method: 'get',
              valueKey: 'jctdscid',
              collection: 'ENT-Jobs',
              // where: jobFilterClauses,
              orderBy: 'JobNumber'
            }}
            optionDisplay={(opts) => {
              if (typeof opts.JobNumber === 'undefined') return '';
              return `${opts.JobNumber}.${opts.SubJobNumber} - ${opts.JobDescription}`;
            }}
            disablePortal
            disabled={CurrentJobNumber.value.length > 0 || checkLockout}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <InputNumber
            name='NumberOfAEPersonnelOnsite'
            value={record.data.NumberOfAEPersonnelOnsite || ''}
            onChange={handleChange}
            label='# of AE Personnel on Jobsite'
            disabled={checkLockout}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <InputText
            name='Weather'
            value={record.data.Weather || ''}
            onChange={handleChange}
            label='Weather'
            disabled={checkLockout}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant='contained' color='primary' onClick={AddAllEquipment}>
            Load All Equipment Assigned To This Job
          </Button>
        </Grid>
        {typeof record.data.JobNumber !== 'undefined' &&
        record.data.JobNumber.length > 0 ? (
          <>
            <Grid item xs={12}>
              <div style={{ marginTop: matches ? '8px' : '0px' }}>
                <RepeatableList
                  key={record.id}
                  user={user}
                  listTransaction={equipmentTransactions}
                  label='Equipment'
                  transactions={record.data.Equipments || []}
                  canAdd
                  canDelete
                  name='Equipments'
                  onChange={onChange}
                  breakpoint='900px'
                  keepLabels
                  rowObjects={[
                    <InputAutocomplete
                      firebaseConfig={firebaseConfig}
                      name='Equipment'
                      label='Equipment'
                      enterprise={false}
                      getOptionKey={(opts) => opts.id}
                      firestoreOptions={{
                        method: 'onSnapshot',
                        collection: 'Equipment',
                        where: [
                          ['data.JobNumber', '==', record.data.JobNumber]
                        ],
                        valueKey: 'id'
                      }}
                      disabled={checkLockout}
                      onChange={handleChange}
                      itemSize={8}
                      optionDisplay={(opts) => {
                        if (Object.keys(opts).length > 0) {
                          if (
                            typeof opts?.data.EquipmentDisplay !==
                              'undefined' &&
                            opts?.data.EquipmentDisplay !== ''
                          ) {
                            return `${opts.data.EquipmentDisplay} - ${opts.data.Description}`;
                          }
                          return `No AE Number - ${opts.data.Description}`;
                        }
                        return '';
                      }}
                    />,
                    <InputTextarea
                      name='Hours'
                      label='Hours'
                      itemSize={3}
                      disabled={checkLockout}
                    />
                  ]}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div style={{ marginTop: matches ? '8px' : '0px' }}>
                <RepeatableList
                  key={record.id}
                  user={user}
                  listTransaction={subcontractorTransactions}
                  label='Subcontractors'
                  transactions={record.data.Subcontractors || []}
                  canAdd
                  canDelete
                  keepLabels
                  name='Subcontractors'
                  onChange={onChange}
                  disabled={checkLockout}
                  breakpoint='900px'
                  rowObjects={[
                    <InputAutocomplete
                      firebaseConfig={firebaseConfig}
                      name='Subcontractor'
                      label='Subcontractor'
                      enterprise={false}
                      disabled={checkLockout}
                      getOptionKey={(opts) => opts.id}
                      firestoreOptions={{
                        method: 'onSnapshot',
                        collection: 'Subcontractor',
                        where: [
                          ['data.JobNumber', '==', record.data.JobNumber]
                        ],
                        valueKey: 'id'
                      }}
                      optionDisplay={['option_Name']}
                      itemSize={4}
                    />,
                    <InputTextarea
                      name='TasksCompleted'
                      label='Tasks Completed'
                      itemSize={6}
                      disabled={checkLockout}
                    />,
                    <InputNumber
                      name='NumberOfSubPersonnel'
                      label='# Of Personnel'
                      itemSize={1}
                      disabled={checkLockout}
                    />
                  ]}
                />
              </div>
            </Grid>
          </>
        ) : (
          <h4 style={{ padding: '27px 0px 0px 4px' }}>
            Input a Job Number to see Equipment, Subcontractors, and Files.
          </h4>
        )}

        <Grid item xs={12}>
          <InputTextarea
            name='InternalNotes'
            value={record.data.InternalNotes || ''}
            onChange={handleChange}
            label='Daily Notes - Internal'
            disabled={checkLockout}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextarea
            name='ExternalNotes'
            value={record.data.ExternalNotes || ''}
            onChange={handleChange}
            label='Daily Notes for GC - External'
            disabled={checkLockout}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextarea
            name='ExtraWorkChangesDetail'
            value={record.data.ExtraWorkChangesDetail || ''}
            onChange={handleChange}
            label='Extra Work / Changes Detail'
            disabled={checkLockout}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              {/* {typeof (record.data.ForemanSignature) !== 'undefined' ? <img src={record.data.ForemanSignature} alt="Foreman Signature" /> : null} */}
              <SignatureCanvas
                ref={(ref) => (ForemanSigRef.current = ref)}
                canvasProps={{
                  width: 300,
                  height: 150,
                  style: { border: '1px solid black' }
                }}
                onEnd={() => updateSignature('Foreman')}
              />
              <Button
                variant='contained'
                color='error'
                className={classes.button}
                startIcon={<FontAwesomeIcon icon={faTimes} />}
                onClick={() => clearSignature('Foreman')}
                style={{ margin: matches ? '5px' : '10px' }}
              >
                Clear Signature
              </Button>
            </Grid>
            <Grid item xs={12}>
              <InputText
                name='ForemanSignaturePrint'
                value={record.data.ForemanSignaturePrint || ''}
                onChange={handleChange}
                label='Foreman - Signature (Print)'
                disabled={checkLockout}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              {/* {record.data.CustomerSignature !== '' ? <img src={record.data.CustomerSignature} alt="Customer Signature" /> : null} */}
              <SignatureCanvas
                ref={CustomerSigRef}
                canvasProps={{
                  width: 300,
                  height: 150,
                  style: { border: '1px solid black' }
                }}
                onEnd={() => updateSignature('Customer')}
              />
              <Button
                variant='contained'
                color='error'
                className={classes.button}
                startIcon={<FontAwesomeIcon icon={faTimes} />}
                onClick={() => clearSignature('Customer')}
                style={{ margin: matches ? '5px' : '10px' }}
              >
                Clear Signature
              </Button>
            </Grid>
            <Grid item xs={12}>
              <InputText
                name='CustomerSignaturePrint'
                value={record.data.CustomerSignaturePrint || ''}
                onChange={handleChange}
                label='Customer - Signature (Print)'
                disabled={checkLockout}
              />
            </Grid>
          </Grid>
        </Grid>
        {typeof record.data.JobNumberDisplay !== 'undefined' &&
        record.data.JobNumberDisplay !== '' ? (
          <Grid item xs={12}>
            <Files
              label='Files'
              name='Files'
              folder={`${record.data.JobNumber}/${CurrentDate.value}/${record.data.Foreman}`}
              bucket={config.storageBucket}
              firebaseConfig={firebaseConfig}
              getFilesFunction='getFiles'
              multiple
            />
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
};

DailyReportForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  setRecord: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  ForemanSigRef: PropTypes.objectOf(PropTypes.any).isRequired,
  CustomerSigRef: PropTypes.objectOf(PropTypes.any).isRequired
};
DailyReportForm.defaultProps = {};
export default DailyReportForm;
