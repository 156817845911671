/* eslint-disable no-unused-vars */
import React from 'react';
import { InputAutocomplete, InputSelect, InputText } from '@aldridge/aldg-data-components';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { firebaseConfig } from '../../firebase';

const ExternalEmployeeEntryForm = (props) => {
  const { currentRecord, setCurrentRecord, user } = props;

  const onChange = (event, name, displayFromTypeahead) => {

    try {
      const nameCanSplit = (n) => {
        if (n.split('.').length > 1) return [true, n.split('.')];
        return [false, n];

      };
      const [canSplit, id] = typeof name !== 'undefined' ? nameCanSplit(name) : nameCanSplit(event.target.name);
      const value = typeof name !== 'undefined' ? event : event.target.value;

      const changedRecord = { ...currentRecord };
      if (canSplit) {
        changedRecord[id[0]][id[1]] = value;
      } else {
        changedRecord[id] = value;
      }
      if (typeof displayFromTypeahead !== 'undefined') {
        if (canSplit) {
          changedRecord[id[0]][`${id[1]}Display`] = displayFromTypeahead;
        } else {
          changedRecord[`${id}Display`] = displayFromTypeahead;
        }
      }

      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setCurrentRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={1}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} md={5} sx={{ px: .5 }}>
            <InputText
              name='PreferredFirstName'
              required
              value={currentRecord?.PreferredFirstName}
              label='First Name'
              onBlur={onChange}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <InputText
              name='LegalLastName'
              required
              value={currentRecord?.LegalLastName}
              label='Last Name'
              onBlur={onChange}
            />
          </Grid>
          <Grid item xs={12} md={2} sx={{ px: .5 }}>
            <InputSelect
              name='Suffix'
              value={currentRecord?.Suffix || ''}
              options={[
                { label: 'Jr', value: 'Jr' },
                { label: 'Sr', value: 'Sr' },
                { label: 'I', value: 'I' },
                { label: 'II', value: 'II' },
                { label: 'III', value: 'III' },

              ]}
              label='Suffix'
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ px: .5 }}>
        <InputSelect
          name='EmployeeType'
          required
          value={currentRecord?.EmployeeType}
          options={[
            { label: 'Field', value: 'Field' },
            { label: 'Offsite', value: 'Offsite' },
            { label: 'Management', value: 'Management' },

          ]}
          label='Employee Type'
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} sx={{ px: .5 }}>
        <InputText
          name='Title'
          required
          value={currentRecord?.Title}
          label='Title'
          onBlur={onChange}
        />
      </Grid>
      <Grid item xs={12} >
        <InputAutocomplete
          firebaseConfig={firebaseConfig}
          onChange={onChange}
          firestoreOptions={{
            method: 'get',
            collection: 'Companies',
            valueKey: 'CompanyNumber',
            orderBy: 'CompanyNumber'
          }}
          value={currentRecord.Company}
          name='Company'
          label='Company'
          optionDisplay={(opt) => `${opt.CompanyNumber} - ${opt.CompanyName}`}
        />
      </Grid>
      <Grid item xs={12} >
        <InputText
          name='EmployeeNumber'
          required
          value={currentRecord?.EmployeeNumber}
          label='Employee Number'
          onBlur={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <InputText
          name='Email'
          required
          value={currentRecord?.Email}
          label='Email'
          onBlur={onChange}
        />
      </Grid>

    </Grid >
  );
};

ExternalEmployeeEntryForm.propTypes = {
  currentRecord: PropTypes.object,
  setCurrentRecord: PropTypes.func,
  user: PropTypes.object
};

ExternalEmployeeEntryForm.defaultProps = {
  currentRecord: {},
  setCurrentRecord: () => { },
  user: {}
};

export default ExternalEmployeeEntryForm;
