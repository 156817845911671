
import React from 'react';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { InputSelect } from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { firestore } from '../../../firebase';


const handleDeleteUsers = (params) => {
  const { row } = params;
  deleteDoc(doc(firestore, 'Users', row.email));
};

const handleRoleUpdate = (p, params) => {
  const changedRecord = JSON.parse(JSON.stringify(params.row));
  if (p.target.value) {
    changedRecord.group = p.target.value;
  }
  updateDoc(doc(firestore, 'Users', params.row.email), changedRecord);
};

const ExternalUsersColumns = [
  {
    field: 'name', headerName: 'Employee Name', width: 200
  },
  { field: 'email', headerName: 'Email', width: 200 },
  {
    field: 'group',
    headerName: 'DJR Role',
    width: 300,
    renderCell: (params) => (
      <InputSelect
        value={params.value || params.row.group}
        options={[
          { label: 'User', value: 'App-DJR-User' },
          { label: 'Prefab', value: 'App-DJR-Prefab' },
          { label: 'Manager', value: 'App-DJR-Manager' },
        ]}
        onChange={(p) => {
          handleRoleUpdate(p, params);
        }}
      />
    )
  },
  {
    field: 'deleteAction',
    headerName: '',
    align: 'right',
    flex: 1,
    renderCell: (params) => (
      <ToolTipIconButton
        icon={faTrash}
        tooltipText="Delete Record"
        shape="circle"
        color="error"
        onClick={() => handleDeleteUsers(params)}
      />
    )
  },
];

export default ExternalUsersColumns;