import React, { useEffect, useContext } from 'react';
// // import { getAnalytics, logEvent } from 'firebase/analytics';
import { Switch, Route } from 'react-router-dom';
// import { useRecoilState } from 'recoil';
import { UserContext } from '../providers/UserProvider';
import GlobalStyles from '../components/Styles/GlobalStyles';
import SignIn from './SignIn';
import Unauthorized from './Unauthorized';
import NoMatch from './NoMatch';
import Dashboard from './Dashboard';
import POD from './POD';
import Crew from './Crew';
import TimeEntry from './TimeEntry';
import WBS from './WBS';
import Equipment from './Equipment';
import TimeSummary from './TimeSummary';
import Subcontractor from './Subcontractor';
import DailyJobReport from './DailyJobReport';
import DistributionPage from './Distribution';
import ReportingToolPDF from './ReportingTool';
import Accounting from './Accounting';
import Test from './Test';
import Training from './Training';
import ChangelogPage from './Changelog';
import SignOut from './SignOut';
import Users from './Users';
import TerminatedForeman from './TerminatedForeman';
import AdminPage from './Admin/AdminPageSec';
// import { _CurrentForeman } from '../_Recoil/atoms';
// import { firestore } from '../firebase';

const Application = () => {
  const { user } = useContext(UserContext);
  // const [, setCurrentForeman] = useRecoilState(_CurrentForeman);
  const page = () => {
    if (!user) {
      return <SignIn />;
    }
    if (user && user.payroll) {
      return (
        <>
          <GlobalStyles />
          <Switch>
            <Route exact path='/' component={Accounting} />
            <Route exact path='/Accounting' component={Accounting} />
            <Route exact path='/Accounting/:id1/:id2' component={Accounting} />
            <Route exact>
              <NoMatch />
            </Route>
          </Switch>
        </>
      );
    }
    if (user && (user.admin || user.user || user.manager || user.prefab)) {
      return (
        <>
          <GlobalStyles />
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/pod' component={POD} />
            <Route exact path='/pod/:id' component={POD} />
            {user.SuperAdmin ? (
              <Route exact path='/Accounting' component={Accounting} />
            ) : null}
            {user.admin ? (
              <Route exact path='/Users' component={Users} />
            ) : null}
            {user.admin ? (
              <Route exact path='/Users/:id' component={Users} />
            ) : null}
            {user.SuperAdmin ? (
              <Route
                exact
                path='/Accounting/:id1/:id2'
                component={Accounting}
              />
            ) : null}
            {user.SuperAdmin ? (
              <Route exact path='/test' component={Test} />
            ) : null}
            <Route exact path='/TerminatedForeman' component={TerminatedForeman} />
            <Route exact path='/Crew' component={Crew} />
            <Route exact path='/Crew/:id' component={Crew} />
            <Route exact path='/DistributionList' component={DistributionPage} />
            <Route exact path='/DistributionList/:id' component={DistributionPage} />
            <Route exact path='/Equipment' component={Equipment} />
            <Route exact path='/Equipment/:id' component={Equipment} />
            <Route exact path='/Subcontractor' component={Subcontractor} />
            <Route exact path='/Subcontractor/:id' component={Subcontractor} />
            <Route exact path='/time-entry' component={TimeEntry} />
            <Route exact path='/WBS' component={WBS} />
            <Route exact path='/WBS/:id' component={WBS} />
            <Route exact path='/Daily-Report' component={DailyJobReport} />
            <Route exact path='/Time-Summary' component={TimeSummary} />
            <Route exact path='/Time-Summary/:id' component={TimeSummary} />
            <Route exact path='/Daily-Report/:id' component={DailyJobReport} />
            <Route exact path='/ReportingTool' component={ReportingToolPDF} />
            <Route exact path='/Training' component={Training} />
            {user.admin ? (<Route exact path='/Admin' component={AdminPage} />) : null}
            <Route exact path='/SignOut' component={SignOut} />
            <Route
              exact
              path='/Changelog'
              component={user.SuperAdmin ? ChangelogPage : NoMatch}
            />
            <Route
              exact
              path='/Changelog/:id'
              component={user.SuperAdmin ? ChangelogPage : NoMatch}
            />

            <Route exact>
              <NoMatch />
            </Route>
          </Switch>
        </>
      );
    }

    return <Unauthorized />;
  };
  useEffect(() => {
    if (user !== null && !user.unauthorized) {
      // // const analytics = getAnalytics();
      // // logEvent(analytics, 'user_login', { user: user.email });
    }
  }, [user]);
  return <div className='appBody'>{page()}</div>;
};
export default Application;
