import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  InputSelect,
  InputText,
  RepeatableList
} from '@aldridge/aldg-data-components';
import { Grid, Button } from '@mui/material';
import { getDoc, doc } from 'firebase/firestore';
import { firestore } from '../../firebase';

const TaskCompletedHours = (props) => {
  const { record, setPOD, customClasses, classes, user, checkLockout } = props;
  const [employees, setEmployees] = useState([]);

  const deleteFunc = (e, f) => {
    const cRecord = { ...record };
    e.splice(f, 1);
    cRecord.data.TimeEntry = e;
    setPOD(cRecord);
  };

  const TimeEntryOnChange = (e) => {
    for (let i = 0; i < e.length; i++) {
      const empData = employees.filter(
        (emp) => emp.data.Employee[0] === e[i].Employee
      );
      e[i].EmployeeDisplay =
        empData.length > 0 ? empData[0].data.EmployeeDisplay : '';
      e[i].Union = empData.length > 0 ? empData[0].data.Union[0] : '';
      e[i].UnionDisplay =
        empData.length > 0 ? empData[0].data.UnionDisplay : '';
      e[i].CrewId = empData.length > 0 ? empData[0].crewId : '';
      e[i].DefaultPerDiem =
        empData.length > 0 ||
        typeof empData[0].data.DefaultPerDiem === 'undefined'
          ? empData[0].data.DefaultPerDiem
          : '';
    }

    const cRecord = { ...record };
    cRecord.data.TimeEntry = e;
    setPOD(cRecord);
  };

  const AddAllWorkingOnCrew = () => {
    const r = {
      Employee: '',
      Shift: '1',
      Regular: '',
      Overtime: '',
      Doubletime: ''
    };
    const allTs = [];

    for (let e = 0; e < employees.length; e++) {
      const uR = JSON.parse(JSON.stringify(r));
      [uR.Employee] = employees[e].data.Employee;
      allTs.push(uR);
    }

    const uRecord = { ...record };
    uRecord.data.TimeEntry = allTs;
    setPOD(uRecord);
  };

  useEffect(() => {
    let mounted = true;
    const CrewRecords = [];
    for (let i = 0; i < record.data.EmployeesWorkingOnTask.length; i++) {
      CrewRecords.push(
        getDoc(doc(firestore, 'Crew', record.data.EmployeesWorkingOnTask[i]))
      );
    }

    Promise.all(CrewRecords).then((crews) => {
      const crewData = [];

      crews.forEach((crew) => {
        const crewR = crew.data();
        if (typeof crewR !== 'undefined') crewR.crewId = crew.id;
        crewData.push(crewR);
      });
      if (mounted) setEmployees(crewData);
    });
    return () => {
      mounted = false;
    };
  }, [record.data.EmployeesWorkingOnTask]);

  console.log(record, employees);
  return (
    <Grid container style={{ margin: 0 }}>
      <Grid item xs={12} className={customClasses.header}>
        <h2>Completed Hours</h2>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant='contained'
          color='primary'
          onClick={AddAllWorkingOnCrew}
        >
          Load Employees That Worked On This Task
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <RepeatableList
          key={record.id}
          user={user}
          listTransaction={{
            Employee: '',
            Regular: '',
            Overtime: '',
            Doubletime: ''
          }}
          label='Hours Log'
          transactions={record.data.TimeEntry || []}
          canAdd
          canDelete
          keepLabels
          name='TimeEntry'
          onChange={TimeEntryOnChange}
          deleteFunc={deleteFunc}
          breakpoint='900px'
          rowObjects={[
            <InputSelect
              name='Employee'
              label='Employee'
              // value={typeof record.data.TimeEntry !== 'undefined' ? record.data.TimeEntry.EmployeeDisplay : ''}
              options={
                typeof record.data.TimeEntry !== 'undefined'
                  ? employees.map((res) => ({
                      value: res?.data.Employee[0],
                      label: res?.data.EmployeeDisplay,
                      disabled: record.data.TimeEntry.map(
                        (e) => e.Employee
                      ).includes(res?.data.Employee[0])
                    }))
                  : employees.map((res) => ({
                      value: res?.data.Employee[0],
                      label: res?.data.EmployeeDisplay
                    }))
              }
              itemSize={4}
              disabled={checkLockout}
            />,
            <InputSelect
              name='Shift'
              label='Shift'
              itemSize={1}
              options={[
                { label: '1', value: '1', default: '1' },
                { label: '2', value: '2' },
                { label: '3', value: '3' }
              ]}
              disabled={checkLockout}
            />,
            <InputText
              name='Regular'
              label='Regular'
              itemSize={2}
              disabled={checkLockout}
            />,
            <InputText
              name='Overtime'
              label='Overtime'
              itemSize={2}
              disabled={checkLockout}
            />,
            <InputText
              name='Doubletime'
              label='Doubletime'
              itemSize={2}
              disabled={checkLockout}
            />
          ]}
        />
      </Grid>
    </Grid>
  );
};

TaskCompletedHours.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  customClasses: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  setPOD: PropTypes.func.isRequired,
  checkLockout: PropTypes.bool
};
TaskCompletedHours.defaultProps = {
  checkLockout: false
};

export default TaskCompletedHours;
