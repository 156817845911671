import { AldgDataList } from '@aldridge/aldg-data-components';
import React from 'react';
import PropTypes from 'prop-types';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { Grid } from '@mui/material';
import ExternalUsersColumns from './columns/ExternalUsersColumns';

const ExternalUsersManagement = (props) => {
  const { ExternalUsers } = props;
  const gridApiRef = useGridApiRef(null);


  return (
    <Grid container sx={{ padding: 1 }}>
      <AldgDataList
        rows={ExternalUsers}
        columns={ExternalUsersColumns}
        apiRef={gridApiRef}
        getRowId={(row) => row.email}
        canAdd={false}
        ToolbarProps={{
          canAdd: false,
          canDelete: false,
        }}
      />
    </Grid>
  );
};

ExternalUsersManagement.propTypes = {
  ExternalUsers: PropTypes.array,
};
ExternalUsersManagement.defaultProps = {
  ExternalUsers: [],
};
export default ExternalUsersManagement;