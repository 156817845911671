import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert, Grid } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import TimeSummaryList from 'components/TimeSummary/TimeSummary';
import { _CurrentDate, _HeaderTitle } from '_Recoil/atoms';
import TimeSummaryDetail from 'components/TimeSummary/TimeSummaryDetail';
import { basicStyles } from 'theme';
import ErrorBoundary from 'utils/ErrorBoundary';

import { UserContext } from 'providers/UserProvider';
import { checkTime } from 'utils/checkLockout';
import dayjs from 'dayjs';
import DailyToolbar from 'components/dailyUsage/DailyToolbar';

const TimeSummary = (props) => {
  const { match, history } = props;
  const { user } = useContext(UserContext);
  const classes = basicStyles();
  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  const CurrentDate = useRecoilValue(_CurrentDate);
  document.title = 'Time Summary';
  useEffect(() => setHeaderTitle('Weekly Time Summary'), []);

  const checkLockout = checkTime(user, CurrentDate);
  const forcedCutoff = checkTime(user, CurrentDate, true);

  return (
    <ErrorBoundary componentName='Time Summary' user={user}>
      <Grid className={classes.gridItem}>
        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ paddingBottom: '8px' }}>
            <h1>{document.title}</h1>
          </div>
        </Grid>
        <DailyToolbar />
        {forcedCutoff && (
          <Grid item xs={12}>
            <Alert severity='warning'>
              Due to Bank Events or Holidays, Time Entry is due by{' '}
              <strong>
                {dayjs(forcedCutoff).format('MM/DD/YY HH:mm A')} CST
              </strong>
              . Please have all time entered by this time. If you do not have
              time entered in the DJR by this time you will need to contact``
              Payroll directly to make sure any missed time is entered.
            </Alert>
          </Grid>
        )}
        {typeof match.params.id === 'undefined' ? (
          <TimeSummaryList
            history={history}
            checkLockout={checkLockout}
            match={match}
          />
        ) : (
          <TimeSummaryDetail
            history={history}
            checkLockout={checkLockout}
            employeeId={match.params.id}
          />
        )}
      </Grid>
    </ErrorBoundary>
  );
};

TimeSummary.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any)
};
TimeSummary.defaultProps = {
  match: {
    params: {}
  },
  history: {
    push: () => {}
  }
};
export default TimeSummary;
