import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { toast } from 'react-toastify';
import {
  faFileUpload,
  faTrash,
  faPlus,
  faToggleOn,
  faLink,
  faFileDownload
} from '@fortawesome/pro-solid-svg-icons';
import { useRecoilValue } from 'recoil';
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
  getDoc,
  writeBatch
} from 'firebase/firestore';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { UserContext } from 'providers/UserProvider';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { firestore } from '../../firebase';
import { _CurrentJobNumber } from '../../_Recoil/atoms';
import uploadWBS from './WBSUpload';
import downloadWBS from './WBSDownload';
import AllWBSSearchBar from './AllWBSSearchBar';
import existsWithLength from '../../utils/existsWithLength';

const gridStyles = makeStyles(
  () => ({
    root: {
      '& .MuiDataGrid-footerContainer': {
        justifyContent: 'flex-start',
        marginLeft: '-12px'
      },
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: '#fff'
      },
      '& .MuiDataGrid-row:nth-child(odd)': {
        backgroundColor: '#efefef'
      },
      '& .MuiDataGrid-row:nth-child(even)': {
        backgroundColor: '#fff'
      }
    }
  }),
  { index: 1 }
);

const WBSList = (props) => {
  const { history } = props;
  const gridClasses = gridStyles();
  const [piccs, setPiccs] = useState([]);
  const [processing, setProcessing] = useState(false);
  const { user } = useContext(UserContext);
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const GridApiRef = useGridApiRef();

  const addNew = () => {
    if (CurrentJobNumber.value.length > 0) history.push(`/wbs/new`);
    else toast.error('You must select a Job before adding a Picc...');
  };

  const getData = (mounted = true) => {
    if (CurrentJobNumber.value.length === 0 && mounted) {
      setPiccs([]);
      return;
    }
    const fRef = query(
      collection(firestore, 'PICC'),
      where('data.JobNumber', '==', CurrentJobNumber.value[0]),
      orderBy('data.Level0', 'asc'),
      orderBy('data.Level1', 'asc'),
      orderBy('data.Level2', 'asc'),
      orderBy('data.Level3', 'asc'),
      orderBy('data.Level4', 'asc')
    );
    getDocs(fRef).then((res) => {
      const allPiccs = [];
      res.forEach((d) => allPiccs.push(d.data()));
      if (mounted) setPiccs(allPiccs);
    });
  };
  useEffect(() => {
    let mounted = true;
    getData(mounted);
    return () => (mounted = false);
  }, [CurrentJobNumber]);

  const _theme = useTheme();
  const matches = useMediaQuery(_theme.breakpoints.down('sm'));
  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const columns = [
    {
      field: 'Status',
      headerName: 'Task Complete?',
      valueGetter: (params) => params.row.data.Complete,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Level1',
      headerName: 'Level 1',
      valueGetter: (params) => params.row.data.Level1,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Level2',
      headerName: 'Level 2',
      valueGetter: (params) => params.row.data.Level2,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Level3',
      headerName: 'Level 3',
      valueGetter: (params) => params.row.data.Level3,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Level4',
      headerName: 'Level 4',
      valueGetter: (params) => params.row.data.Level4,
      flex: getFlex(15),
      width: getWidth(300)
    },
    // {
    //   field: 'UploadedPICC',
    //   headerName: 'Uploaded PICC',
    //   valueGetter: (params) => params.row.data.PayItem,
    //   flex: getFlex(15),
    //   width: getWidth(300)
    // },
    {
      field: 'PICC',
      headerName: 'PICC',
      valueGetter: (params) =>
        params.row.data.PiccDisplay ||
        `${params.row.data.PayItem} [Could Not Find Active PICC in ECMS]`,
      flex: getFlex(15),
      width: getWidth(300)
    }
  ];

  const [rows, setRows] = useState(piccs);

  useEffect(() => {
    let mounted = true;
    if (mounted) setRows(piccs);
    return () => (mounted = false);
  }, [piccs]);

  const deleteRecords = async () => {
    let batch = writeBatch(firestore);
    const selectedRows = Array.from(GridApiRef.current.getSelectedRows());
    setProcessing(true);
    for (let s = 0; s < selectedRows.length; s++) {
      if (s !== 0 && s % 500 === 0) {
        // eslint-disable-next-line no-await-in-loop
        await batch.commit();
        batch = writeBatch(firestore);
      }
      const docRef = doc(firestore, 'PICC', selectedRows[s][0]);
      batch.delete(docRef);
    }
    await batch.commit();
    getData();
    setProcessing(false);
    toast.success(
      'Record(s) deleted successfully! Refresh the page to see updates.'
    );
  };

  const toggleCompleteStatus = () => {
    const selectedRows = Array.from(GridApiRef.current.getSelectedRows());
    const knt = Array.from(GridApiRef.current.getSelectedRows()).length;
    const pm = [];
    setProcessing(true);

    selectedRows.forEach((s) => {
      getDoc(doc(firestore, 'PICC', s[0])).then((d) => {
        const docRef = d.data();
        docRef.data.Complete = docRef.data.Complete === 'Yes' ? 'No' : 'Yes';
        pm.push(setDoc(d.ref, docRef, { merge: true }));
      });
    });
    Promise.all(pm).then(() => {
      setProcessing(false);
      toast.info(
        `${knt} WBS Codes updated had their Completion Status updated. Please refresh the page to see the updates.`
      );
    });
  };

  const AllWBSFilter = (wbsID) => {
    if (wbsID.length === 0) setRows(piccs);
    else setRows(rows.filter((r) => r.id === wbsID[0]));
  };

  const REVERIFY = () => {
    const fRef = query(
      collection(firestore, 'ENT-Piccs'),
      where('jctdscid', '==', CurrentJobNumber.value[0])
    );
    setProcessing(true);
    getDocs(fRef).then((snap) => {
      const allPiccs = [];
      snap.forEach((d) => allPiccs.push(d.data()));

      const missingEntPiccs = rows.filter((r) => r.data.Picc.length === 0);
      missingEntPiccs.map((res) => {
        const foundPicc = allPiccs.filter((r) => r.PICC === res.data.PayItem);
        if (foundPicc.length > 0) {
          res.data.Picc = [foundPicc[0].jctmstid];
          res.data.PiccDisplay = `${foundPicc[0].PICC} - ${foundPicc[0].Description}`;
        }
        return res;
      });
      const recordsFound = missingEntPiccs.filter((r) =>
        existsWithLength(r.data.Picc)
      );
      const stillMissingEntPiccs = missingEntPiccs.filter(
        (r) => r.data.Picc.length === 0
      );

      const p = [];
      for (let i = 0; i < recordsFound.length; i++) {
        p.push(
          setDoc(doc(firestore, 'PICC', recordsFound[i].id), recordsFound[i], {
            merge: true
          })
        );
      }

      Promise.all(p).then(() => {
        setProcessing(false);
        toast.success(
          `Successfully connected ${recordsFound.length} PICC(s) to the WBS. There are a total of ${stillMissingEntPiccs.length} WBS Items missing a PICC. Please refresh the application to see the changes.`,
          { autoClose: 10000 }
        );
      });
    });
  };

  return (
    <>
      <div style={{ paddingTop: '10px' }} />
      <Alert severity='info'>
        Please do not close the website until the Sync cloud in the upper right
        corner has turned green. Closing the web page before hand could result
        in lost data.
      </Alert>
      <div
        style={{
          display: 'flex',
          width: '100%',
          padding: matches ? '20px 0' : '10px 0',
          justifyContent: 'flex-end'
        }}
      >
        <AllWBSSearchBar
          JobNumber={CurrentJobNumber.value[0]}
          onChange={(e) => AllWBSFilter(e)}
          completeStatus='Both'
        />

        <ToolTipIconButton
          icon={faToggleOn}
          tooltipText='Toggle Complete Status'
          onClick={toggleCompleteStatus}
          color='gray'
          loading={processing}
        />

        <ToolTipIconButton
          icon={faLink}
          tooltipText='Re-Link PICC'
          onClick={REVERIFY}
          color='success'
          loading={processing}
        />

        <ToolTipIconButton
          icon={faFileDownload}
          tooltipText='Download WBS'
          onClick={() =>
            downloadWBS(
              Array.from(GridApiRef.current.getSelectedRows()),
              piccs,
              CurrentJobNumber
            )
          }
          loading={processing}
        />

        <ToolTipIconButton
          icon={faFileUpload}
          tooltipText='Upload WBS'
          onClick={() =>
            uploadWBS(user, CurrentJobNumber, getData, setProcessing)
          }
          loading={processing}
        />

        <ToolTipIconButton
          icon={faTrash}
          tooltipText='Delete Selected Records'
          onClick={deleteRecords}
          color='error'
          loading={processing}
        />
        <ToolTipIconButton
          icon={faPlus}
          tooltipText='Add New WBS Record'
          onClick={addNew}
          loading={processing}
        />
      </div>
      <AldgDataGrid
        autoHeight
        pagination
        pageSize={50}
        checkboxSelection
        onRowClick={(cell) => {
          if (cell.field === '__check__') return;
          history.push(`/wbs/${cell.id}`);
        }}
        className={gridClasses.root}
        rows={rows}
        columns={columns}
        apiRef={GridApiRef}
        // loading
      />
    </>
  );
};

WBSList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};
WBSList.defaultProps = {};

export default WBSList;
