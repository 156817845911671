/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Paper, Typography } from '@mui/material';
import Admin from 'Pages/Admin';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { firestore } from '../../firebase';
import ExternalEmployeeEntry from './ExternalEmployeeEntry';
import ExternalUsersManagement from './ExternalUsersManagement';
import CompanyManagement from './CompanyManagement';

const AdminPage = () => {
  const [Employees, setEmployees] = useState([]);
  const [ExternalUsers, setExternalUsers] = useState([]);
  const [Companies, setCompanies] = useState([]);

  const loadExternalEmployees = () => {
    const EMPLOYEESCOLLECTION = `ENT-Employees`;
    let employeesQuery = query(collection(firestore, EMPLOYEESCOLLECTION));
    employeesQuery = query(employeesQuery, where('IsAldridgeEmployee', '!=', true));
    onSnapshot(employeesQuery, (snapshot) => {
      const employees = [];
      snapshot?.forEach((d) => {
        employees.push(d.data());
      });
      setEmployees(employees);
    });
  };

  const loadExternalUsers = () => {
    const USERCOLLECTION = 'Users';
    let usersQuery = query(collection(firestore, USERCOLLECTION));
    usersQuery = query(usersQuery, where('IsAldridgeEmployee', '!=', true));
    onSnapshot(usersQuery, (snapshot) => {
      const users = [];
      snapshot?.forEach((d) => {
        users.push(d.data());
      });
      setExternalUsers(users);
    });
  };
  const loadCompanies = () => {
    const COMPANYCOLLECTION = 'Companies';
    const companiesQuery = query(collection(firestore, COMPANYCOLLECTION));
    onSnapshot(companiesQuery, (snapshot) => {
      const companies = [];
      snapshot?.forEach((d) => {
        companies.push(d.data());
      });
      setCompanies(companies);
    });
  };

  useEffect(() => {
    loadExternalEmployees();
    loadExternalUsers();
    loadCompanies();
  }, []);

  return (
    <div>
      <Typography variant="h4" gutterBottom align='left'>
        Admin Management
      </Typography>
      <Grid container>

        <Grid item xs={9} sx={{ padding: 1 }}>
          <Paper variant='outlined'>
            <Typography align='left' variant='h5' sx={{ padding: 1 }}> External Employees</Typography>
            <ExternalEmployeeEntry Employees={Employees} ExternalUsers={ExternalUsers} reloadEmployees={loadExternalEmployees} reloadUsers={loadExternalUsers} />
          </Paper>
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <Paper variant='outlined'>
            <Typography align='left' variant='h5' sx={{ padding: 1 }}> External Companies</Typography>
            <CompanyManagement Companies={Companies} />
          </Paper>
        </Grid>
        <Grid item xs={12} sx={{ padding: 1 }}>
          <Paper variant='outlined'>
            <Typography align='left' variant='h5' sx={{ padding: 1 }}> External Users</Typography>
            <ExternalUsersManagement ExternalUsers={ExternalUsers} />
          </Paper>
        </Grid>
        <Grid item xs={12} sx={{ padding: 1 }}>
          <Paper variant='outlined'>
            <Admin />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminPage;