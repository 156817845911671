import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  InputText,
  InputCheckbox,
  AldgDataGrid
} from '@aldridge/aldg-data-components';
import {
  Button,
  Checkbox,
  createTheme,
  FormLabel,
  Grid,
  Paper,
  useMediaQuery
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/pro-regular-svg-icons';

import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  where
} from 'firebase/firestore';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { icons, basicStyles } from '../../theme';
import { firestore } from '../../firebase';
import { UserContext } from '../../providers/UserProvider';
import QuickSearchToolbar from '../../_GlobalComponents/QuickFilterToolbar';

const UserPage = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { history, match } = props;
  const iconClasses = icons();
  const classes = basicStyles();
  const { user } = useContext(UserContext);
  const [record, setRecord] = useState({
    name: '',
    email: '',
    phone: '',
    group: ''
  });
  // eslint-disable-next-line no-unused-vars
  const [rows, setRows] = useState([]);
  const GridApiRef = useGridApiRef();

  useEffect(() => {
    onSnapshot(doc(firestore, 'Users', match.params.id), (snap) => {
      const data = snap.data();
      setRecord(data);
    });
  }, [match.params.id]);

  useEffect(() => {
    onSnapshot(
      query(
        collection(firestore, 'ENT-Jobs'),
        where('JobStatus', '==', 'Open'),
        orderBy('JobNumber')
      ),
      (snap) => {
        const all = [];
        snap.forEach((d) => {
          all.push(d.data());
        });
        setRows(all);
      }
    );
  }, []);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const nameCanSplit = (n) => {
        if (n.split('.').length > 1) return [true, n.split('.')];
        return [false, n];
      };
      const [canSplit, id] =
        typeof name !== 'undefined'
          ? nameCanSplit(name)
          : nameCanSplit(event.target.name);
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      if (changedRecord.CreatedBy === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      if (canSplit) {
        changedRecord.data[id[0]][id[1]] = value;
      } else {
        changedRecord.data[id] = value;
      }

      if (typeof displayFromTypeahead !== 'undefined') {
        if (canSplit) {
          changedRecord.data[id[0]][`${id[1]}Display`] = displayFromTypeahead;
        } else {
          changedRecord.data[`${id}Display`] = displayFromTypeahead;
        }
      }
      setRecord(changedRecord);
    } catch (err) {
      /* toast.error(err.message); */
    }
  };
  const addUser = () => { };
  const goBack = () => {
    history.goBack();
  };

  const _theme = createTheme();
  const matches = useMediaQuery(_theme.breakpoints.down('sm'));
  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const columns = [
    {
      field: 'Job',
      headerName: 'Job',
      valueGetter: (params) =>
        `${params.row.JobNumber}.${params.row.SubJobNumber}`,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Admin',
      headerName: 'Admin',
      renderCell: () => <Checkbox value='Admin' />,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Manager',
      headerName: 'Manager',
      renderCell: () => <Checkbox value='Manager' />,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'PreFab',
      headerName: 'PreFab',
      renderCell: () => <Checkbox value='PreFab' />,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'User',
      headerName: 'User',
      renderCell: () => <Checkbox value='User' />,
      flex: getFlex(15),
      width: getWidth(300)
    }
  ];

  const clonedQuickSearch = React.cloneElement(QuickSearchToolbar);
  return (
    <div style={{ paddingTop: '16px' }}>
      <div
        style={{
          position: 'sticky',
          top: '-1px',
          left: 0,
          right: 0,
          width: '100%',
          padding: '8px',
          paddingRight: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
          zIndex: 100
        }}
      >
        <div
          className={`${iconClasses.container} ${iconClasses.greenContainer}`}
          title='Save Record'
        >
          <Button
            onClick={addUser}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon icon={faSave} className={iconClasses.icon} />
          </Button>
        </div>
        <div
          className={`${iconClasses.container} ${iconClasses.redContainer}`}
          style={{ margin: '0px 0px 0px 12px' }}
          title='Close Record'
        >
          <Button
            onClick={goBack}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon icon={faTimes} className={iconClasses.icon} />
          </Button>
        </div>
      </div>
      <Paper className={classes.simplePaper}>
        <Grid container>
          <Grid item className={classes.field} xs={12} md={4}>
            <InputText
              label='Name'
              name='name'
              onChange={onChange}
              value={record.name || ''}
            />
          </Grid>
          <Grid item className={classes.field} xs={12} md={4}>
            <InputText
              label='Email'
              name='email'
              onChange={onChange}
              value={record.email || ''}
            />
          </Grid>
          <Grid item className={classes.field} xs={12} md={4}>
            <InputText
              label='Phone'
              name='phone'
              onChange={onChange}
              value={record.phone || ''}
            />
          </Grid>
          <Grid item className={classes.field} xs={12} flexDirection='column'>
            <Grid container>
              <Grid item xs={12}>
                PERMISSION SETTINGS...
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <FormLabel sx={{ fontWeight: 'bold' }}>
                  Global Permissions
                </FormLabel>
              </Grid>
              <Grid item xs={3}>
                <InputCheckbox
                  checkboxes={[{ value: 'Admin', label: `Admin` }]}
                  LabelProps={{
                    label: '',
                  }}
                  name='global'
                  onChange={onChange}
                  value={record.global || ''}
                />
              </Grid>
              <Grid item xs={3}>
                <InputCheckbox
                  checkboxes={[{ value: 'Manager', label: `Manager` }]}
                  LabelProps={{
                    label: '',
                  }}
                  name='global'
                  onChange={onChange}
                  value={record.global || ''}
                />
              </Grid>
              <Grid item xs={3}>
                <InputCheckbox
                  checkboxes={[{ value: 'PreFab', label: `PreFab` }]}
                  LabelProps={{
                    label: '',
                  }}
                  name='global'
                  onChange={onChange}
                  value={record.global || ''}
                />
              </Grid>
              <Grid item xs={3}>
                <InputCheckbox
                  checkboxes={[{ value: 'User', label: `User` }]}
                  LabelProps={{
                    label: '',
                  }}
                  name='global'
                  onChange={onChange}
                  value={record.global || ''}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <AldgDataGrid
                  autoHeight
                  pagination
                  components={{ Toolbar: clonedQuickSearch }}
                  getRowId={(r) => r.jctdscid}
                  getRowHeight={() => 'auto'}
                  rows={rows}
                  columns={columns}
                  apiRef={GridApiRef}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

UserPage.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired
};
UserPage.defaultProps = {};

export default UserPage;
