import React from 'react';
import { InputText } from '@aldridge/aldg-data-components';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';

const CompanyManagementForm = (props) => {
  const { currentRecord, setCurrentRecord, user } = props;

  const onChange = (event, name, displayFromTypeahead) => {

    try {
      const nameCanSplit = (n) => {
        if (n.split('.').length > 1) return [true, n.split('.')];
        return [false, n];

      };
      const [canSplit, id] = typeof name !== 'undefined' ? nameCanSplit(name) : nameCanSplit(event.target.name);
      const value = typeof name !== 'undefined' ? event : event.target.value;

      const changedRecord = { ...currentRecord };
      if (canSplit) {
        changedRecord[id[0]][id[1]] = value;
      } else {
        changedRecord[id] = value;
      }
      if (typeof displayFromTypeahead !== 'undefined') {
        if (canSplit) {
          changedRecord[id[0]][`${id[1]}Display`] = displayFromTypeahead;
        } else {
          changedRecord[`${id}Display`] = displayFromTypeahead;
        }
      }

      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setCurrentRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={1}>
      <Grid item xs={12} sx={{ px: .5 }}>
        <InputText
          name='CompanyNumber'
          required
          value={currentRecord?.CompanyNumber}
          label='Company Number'
          onBlur={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <InputText
          name='CompanyName'
          required
          value={currentRecord?.CompanyName}
          label='Company Name'
          onBlur={onChange}
        />
      </Grid>


    </Grid >
  );
};

CompanyManagementForm.propTypes = {
  currentRecord: PropTypes.object,
  setCurrentRecord: PropTypes.func,
  user: PropTypes.object
};

CompanyManagementForm.defaultProps = {
  currentRecord: {},
  setCurrentRecord: () => { },
  user: {}
};

export default CompanyManagementForm;
