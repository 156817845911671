import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { faTrash, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import {
  deleteDoc,
  onSnapshot,
  doc,
  query,
  where,
  collection
} from 'firebase/firestore';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { _CurrentForeman, _CurrentJobNumber } from '../../_Recoil/atoms';
import { firestore } from '../../firebase';


const gridStyles = makeStyles(
  () => ({
    root: {
      '& .MuiDataGrid-footerContainer': {
        justifyContent: 'flex-start',
        marginLeft: '-12px'
      },
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: '#fff'
      },
      '& .MuiDataGrid-row:nth-child(odd)': {
        backgroundColor: '#efefef'
      },
      '& .MuiDataGrid-row:nth-child(even)': {
        backgroundColor: '#fff'
      }
    }
  }),
  { index: 1 }
);

const DistributionList = (props) => {
  const { history } = props;
  const [distribution, setDistribution] = useState([]);
  const [rows, setRows] = useState(distribution);
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const GridApiRef = useGridApiRef();
  const gridClasses = gridStyles();

  useEffect(() => {
    let mounted = true;
    if (CurrentJobNumber.value.length === 0) {
      setDistribution([]);
      toast.warn(
        'You must have a Job Number and Foreman to pull the distribution list.'
      );
      return () => null;
    }
    const q = query(
      collection(firestore, 'Distribution'),
      where('data.JobNumber', '==', CurrentJobNumber.value[0])
    );
    onSnapshot(q, (querySnapshot) => {
      const allDistributions = [];
      querySnapshot.forEach((d) => {
        allDistributions.push(d.data());
      });
      if (mounted) setDistribution(allDistributions);
    });
    return () => (mounted = false);
  }, [CurrentJobNumber, CurrentForeman]);

  useEffect(() => {
    let mounted = true;
    if (mounted) setRows(distribution);
    return () => (mounted = false);
  }, [distribution]);

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const handleSelect = (idx) => {
    history.push(`/DistributionList/${idx}`);
  };
  const addNew = () => {
    if (CurrentJobNumber.value.length > 0 && CurrentForeman.value.length > 0)
      history.push(`/DistributionList/new`);
    else
      toast.error(
        'You must select a Job- & Foreman before adding Distribution.'
      );
  };
  const deleteRecords = () => {
    const selectedRows = Array.from(GridApiRef.current.getSelectedRows()?.entries());
    selectedRows.forEach((s) => {
      const [id] = s;
      deleteDoc(doc(firestore, 'Distribution', id));
    });
  };

  const columns = [
    {
      field: 'Employee',
      headerName: 'Employee',
      valueGetter: (params) => params.row.data.EmployeeDisplay,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Email',
      headerName: 'Email',
      valueGetter: (params) => params.row.data.Employee,
      flex: getFlex(15),
      width: getWidth(300)
    }
  ];
  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          padding: matches ? '20px 0' : '10px 0',
          justifyContent: 'flex-end'
        }}
      >
        <ToolTipIconButton icon={faTrash} color="error" tooltipText='Delete Selected Records' onClick={deleteRecords} />
        <ToolTipIconButton icon={faPlus} tooltipText='Add New Record' onClick={addNew} />

      </div>
      <div style={{ height: '100%', width: '100%' }}>
        <AldgDataGrid
          autoHeight
          checkboxSelection
          onRowClick={(row) => handleSelect(row.id)}
          className={gridClasses.root}
          rows={rows}
          columns={columns}
          apiRef={GridApiRef}
        />
      </div>
    </>
  );
};

DistributionList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any)
};

DistributionList.defaultProps = {
  history: {
    push: () => { }
  }
};

export default DistributionList;
