import { RecoilRoot } from 'recoil';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Loader from 'react-loader-spinner';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

import { OfflineProvider } from '@aldridge/aldg-helpers';
import SizeProvider from 'providers/SizeProvider';
import Header from './_GlobalComponents/Header';
import Footer from './_GlobalComponents/Footer';
import Application from './Pages/Application';
import UserProvider from './providers/UserProvider';
import OfflineDataProvider from './providers/OfflineDataProvider';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import { config } from './firebase';
import ChangelogModal from './ChangelogModal';
import ErrorBoundary from './utils/ErrorBoundary';

import './_assets/base.scss';
import 'react-toastify/dist/ReactToastify.css';

const rootElement = document.getElementById('root');
const root = createRoot(document.getElementById('root'));

const renderApp = () => {

  // const theme = createTheme({
  //   palette: {
  //     primary: {
  //       main: '#3f51b5',
  //       dark: '#26389C'
  //     },
  //     secondary: {
  //       main: '#f50057',
  //       dark: '#DC003E'
  //     },
  //     info: {
  //       main: '#e0e0e0'
  //     }
  //   }
  // });
  const configClass = `${config.projectId.indexOf('-dev') > -1 ? 'bg-dev' : ''
    } ${config.projectId.indexOf('-training') > -1 ? 'bg-training' : ''}`.trim();
  try {
    root.render(
      <StyledEngineProvider injectFirst>
        {/* <ThemeProvider theme={theme}> */}
        <SizeProvider>
          <RecoilRoot>
            <OfflineProvider>
              <UserProvider>
                <ErrorBoundary
                  componentName='Main Application'
                  user={{ email: '' }}
                >
                  <OfflineDataProvider>
                    <BrowserRouter>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <ToastContainer
                          position='bottom-left'
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                        />
                        <Suspense
                          fallback={
                            <div className='loader-container'>
                              <div className='loader-container-inner'>
                                <div className='text-center'>
                                  <Loader type='TailSpin' />
                                </div>
                                <h6 className='mt-3'>
                                  Please wait while we load the Application
                                </h6>
                              </div>
                            </div>
                          }
                        >
                          <div
                            id='appParent'
                            className={configClass}
                            style={{
                              display: 'flex',
                              height: '100%',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              alignItems: 'center'
                            }}
                          >
                            <ChangelogModal />
                            <Header />
                            <Application />
                            <Footer
                              copy={`${new Date().getFullYear()} Aldridge, All Rights Reserved`}
                            />
                            <ServiceWorkerWrapper />
                          </div>
                        </Suspense>
                      </LocalizationProvider>
                    </BrowserRouter>
                  </OfflineDataProvider>
                </ErrorBoundary>
              </UserProvider>
            </OfflineProvider>
          </RecoilRoot>
        </SizeProvider>
        {/* </ThemeProvider> */}
      </StyledEngineProvider>,
      rootElement
    );
  } catch (err) {
    // eslint-disable-next-line
    console.log('RENDERING FAILED');
  }
};
renderApp();
// serviceWorkerRegistration.register();
