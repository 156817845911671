import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  Paper,
  Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  faChevronDown,
  faChevronUp,
  faTrash
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  query,
  onSnapshot,
  orderBy,
  collection,
  where
} from 'firebase/firestore';
import {
  InputTextarea,
  InputNumber,
  InputSelect,
  InputCheckbox
} from '@aldridge/aldg-data-components';
import { basicStyles, icons } from '../../theme';
import { firestore } from '../../firebase';

const TimeEntryTable = (props) => {
  const iconClasses = icons();
  const { data, onChange, onDelete, checkLockout } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const matches2 = useMediaQuery(theme.breakpoints.down('md'));
  const [PerDiems, setPerDiems] = useState([]);
  const useStyles = makeStyles(
    () => ({
      select: {
        [theme.breakpoints.down('sm')]: {
          padding: '4px 8px 0px 0px'
        },
        [theme.breakpoints.up('sm')]: {
          padding: '0px 8px 0px 0px'
        }
      }
    }),
    { index: 1 }
  );
  const customClasses = useStyles();

  const handleTableChange = (idx, event, name, displayFromTypeahead) => {
    onChange(idx, event, name, displayFromTypeahead);
  };
  const classes = basicStyles();

  useEffect(() => {
    let mounted = true;
    const pds = [];
    const q = query(
      collection(firestore, 'PerDiems'),
      where('status', '==', 'A'),
      orderBy('order')
    );
    onSnapshot(q, (res) => {
      res.forEach((d) => {
        pds.push(d.data());
      });
      if (mounted) setPerDiems(pds);
    });
    return () => {
      mounted = false;
    };
  }, []);

  const xsDownTable = () => (
    <Paper className={classes.paperWMargin} style={{ margin: '1px' }}>
      <Grid item xs={12}>
        <Grid item xs={11} className={classes.headerRow} />
        {data.map((d, idx) => (
          <Grid item xs={12} key={d.id === '' ? idx.toString() : d.id}>
            <div className='bgCard' style={{ padding: '4px' }}>
              <Card raised>
                <CardContent>
                  <Grid container>
                    <Grid item xs={11}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container alignItems='flex-end'>
                            <Grid item xs={6} style={{ paddingRight: '8px' }}>
                              <InputTextarea
                                name='PersonJobPiccDisplay'
                                value={d.data.PersonJobPiccDisplay}
                                disabled
                              />
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              className={customClasses.select}
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <InputSelect
                                disabled={checkLockout}
                                name='Shift'
                                label='Shift'
                                onChange={(e, f, g, h, i) =>
                                  handleTableChange(idx, e, f, g, h, i)
                                }
                                value={d.data.Shift || ''}
                                options={[
                                  { label: '1', value: '1', default: '1' },
                                  { value: '2', label: '2' },
                                  { value: '3', label: '3' }
                                ]}
                              />
                              <InputSelect
                                disabled={checkLockout}
                                name='PerDiem'
                                label='Per Diem'
                                value={d.data.PerDiem || ''}
                                onChange={(e, f, g, h, i) =>
                                  handleTableChange(idx, e, f, g, h, i)
                                }
                                options={PerDiems}
                              />
                              <InputSelect
                                disabled={checkLockout}
                                name='ExemptFromCertifiedPayroll'
                                label='Exempt'
                                onChange={(e, f, g, h, i) =>
                                  handleTableChange(idx, e, f, g, h, i)
                                }
                                value={d.data.ExemptFromCertifiedPayroll || ''}
                                options={[
                                  { value: 'true', label: 'Yes' },
                                  { value: 'false', label: 'No' }
                                ]}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid
                                  item
                                  xs={d.data.ArrowOpen ? 6 : 12}
                                  className={customClasses.select}
                                >
                                  <InputNumber
                                    disabled={checkLockout}
                                    name='Regular'
                                    label='Regular'
                                    onChange={(e, f, g, h, i) =>
                                      handleTableChange(idx, e, f, g, h, i)
                                    }
                                    value={d.data.Regular}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={d.data.ArrowOpen ? 6 : 12}
                                  className={customClasses.select}
                                >
                                  <InputNumber
                                    disabled={checkLockout}
                                    name='Overtime'
                                    label='Overtime'
                                    onChange={(e, f, g, h, i) =>
                                      handleTableChange(idx, e, f, g, h, i)
                                    }
                                    value={d.data.Overtime}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={d.data.ArrowOpen ? 6 : 12}
                                  className={customClasses.select}
                                >
                                  <InputNumber
                                    disabled={checkLockout}
                                    name='Doubletime'
                                    label='Doubletime'
                                    onChange={(e, f, g, h, i) =>
                                      handleTableChange(idx, e, f, g, h, i)
                                    }
                                    value={d.data.Doubletime}
                                  />
                                </Grid>
                                {d.data.ArrowOpen ? (
                                  <>
                                    <Grid
                                      item
                                      xs={6}
                                      className={customClasses.select}
                                    >
                                      <InputNumber
                                        disabled={checkLockout}
                                        name='Vacation'
                                        label='Vacation'
                                        onChange={(e, f, g, h, i) =>
                                          handleTableChange(idx, e, f, g, h, i)
                                        }
                                        value={d.data.Vacation}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      className={customClasses.select}
                                    >
                                      <InputNumber
                                        disabled={checkLockout}
                                        name='Holiday'
                                        label='Holiday'
                                        onChange={(e, f, g, h, i) =>
                                          handleTableChange(idx, e, f, g, h, i)
                                        }
                                        value={d.data.Holiday}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      className={customClasses.select}
                                    >
                                      <InputNumber
                                        disabled={checkLockout}
                                        name='SickLeave'
                                        label='Sick Leave'
                                        onChange={(e, f, g, h, i) =>
                                          handleTableChange(idx, e, f, g, h, i)
                                        }
                                        value={d.data.SickLeave}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      className={customClasses.select}
                                    >
                                      <InputSelect
                                        disabled={checkLockout}
                                        name='data.SickLeaveType'
                                        label='Sick Leave Type'
                                        onChange={(e, f, g, h, i) =>
                                          handleTableChange(idx, e, f, g, h, i)
                                        }
                                        options={[
                                          { label: 'SL', value: 'California' },
                                          { value: 'S1', label: 'MD/DC' },
                                          {
                                            value: 'S2',
                                            label: 'IBEW 26 Sick Leave'
                                          }
                                        ]}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        textAlign: 'left',
                                        margin: '4px 0px -8px 0px',
                                        textTransform: 'uppercase'
                                      }}
                                      className={customClasses.select}
                                    >
                                      <InputCheckbox
                                        checkboxes={[
                                          { value: 'true', label: 'No work recorded.' },
                                        ]}
                                        LabelProps={{
                                          label: '',
                                          disabled: checkLockout
                                        }}
                                        name="NoWorkRecorded"
                                        onChange={(e, f, g, h, i) =>
                                          handleTableChange(idx, e, f, g, h, i)
                                        }
                                        value={d.data.NoWorkRecorded}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        textAlign: 'left',
                                        margin: '4px 0px -8px 0px'
                                      }}
                                      className={customClasses.select}
                                    >
                                      <InputCheckbox
                                        checkboxes={[
                                          { value: 'true', label: 'Under 8 hours verified.' },
                                        ]}
                                        LabelProps={{
                                          label: '',
                                          disabled: checkLockout
                                        }}
                                        name="LessThan8HoursVerified"
                                        onChange={(e, f, g, h, i) =>
                                          handleTableChange(idx, e, f, g, h, i)
                                        }
                                        value={d.data.LessThan8HoursVerified}
                                      />
                                    </Grid>
                                  </>
                                ) : null}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          width: '-webkit-fill-available',
                          margin: '0px -4px 10px 0px'
                        }}
                      >
                        {!checkLockout && <div
                          className={`${iconClasses.container} ${iconClasses.trashContainer}`}
                          style={{ margin: matches ? '0px -8px' : '0px 8px' }}
                        >
                          <Button
                            disableRipple
                            style={{
                              backgoundColor: 'transparent',
                              padding: '0px',
                              minWidth: '40px',
                              maxWidth: '40px',
                              minHeight: '40px',
                              maxHeight: '40px',
                              borderRadius: '20px'
                            }}
                            onClick={() => onDelete(data, idx)}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className={iconClasses.trashIcon}
                              style={{ cursor: 'pointer' }}
                            />{' '}
                          </Button>
                        </div>}
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          width: '-webkit-fill-available',
                          margin: '10px -4px 10px 0px'
                        }}
                      >
                        <div
                          className={`${iconClasses.container} ${iconClasses.trashContainer}`}
                          style={{ margin: matches ? '0px -8px' : '0px 8px' }}
                        >
                          <Button
                            disableRipple
                            style={{
                              backgoundColor: 'transparent',
                              padding: '0px',
                              minWidth: '40px',
                              maxWidth: '40px',
                              minHeight: '40px',
                              maxHeight: '40px',
                              borderRadius: '20px'
                            }}
                            onClick={(g, h, i) =>
                              handleTableChange(
                                idx,
                                !d.data.ArrowOpen,
                                'ArrowOpen',
                                g,
                                h,
                                i
                              )
                            }
                          >
                            <FontAwesomeIcon
                              icon={
                                d.data.ArrowOpen ? faChevronUp : faChevronDown
                              }
                              className={iconClasses.trashIcon}
                            />{' '}
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
  const smUpTable = () => (
    <Paper className={classes.paperWMargin} style={{ margin: '1px' }}>
      <Grid item xs={12}>
        {data.map((d, idx) => (
          <Grid item xs={12} key={d.id === '' ? idx.toString() : d.id}>
            <div className='bgCard' style={{ padding: '4px' }}>
              <Card raised>
                <CardContent style={{ paddingRight: '0px' }}>
                  <Grid container>
                    <Grid item xs={11}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container alignItems='flex-end'>
                            <Grid
                              item
                              xs={3}
                              style={{
                                display: 'grid',
                                padding: '2px 8px 0px 0px'
                              }}
                            >
                              <InputTextarea
                                name='PersonJobPiccDisplay'
                                value={d.data.PersonJobPiccDisplay}
                                disabled
                              />
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              className={customClasses.select}
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <InputSelect
                                disabled={checkLockout}
                                name='Shift'
                                label='Shift'
                                onChange={(e, f, g, h, i) =>
                                  handleTableChange(idx, e, f, g, h, i)
                                }
                                value={d.data.Shift || ''}
                                options={[
                                  { value: '1', label: '1' },
                                  { value: '2', label: '2' },
                                  { value: '3', label: '3' }
                                ]}
                              />
                              <InputSelect
                                disabled={checkLockout}
                                name='PerDiem'
                                label='Per Diem'
                                value={d.data.PerDiem || ''}
                                onChange={(e, f, g, h, i) =>
                                  handleTableChange(idx, e, f, g, h, i)
                                }
                                options={PerDiems}
                              />
                              <InputSelect
                                disabled={checkLockout}
                                name='ExemptFromCertifiedPayroll'
                                label='Exempt'
                                onChange={(e, f, g, h, i) =>
                                  handleTableChange(idx, e, f, g, h, i)
                                }
                                value={d.data.ExemptFromCertifiedPayroll || ''}
                                options={[
                                  { value: 'true', label: 'Yes' },
                                  { value: 'false', label: 'No' }
                                ]}
                              />
                            </Grid>
                            <Grid item xs={7}>
                              <Grid container>
                                <Grid
                                  item
                                  xs={4}
                                  className={customClasses.select}
                                >
                                  <InputNumber
                                    disabled={checkLockout}
                                    name='Regular'
                                    label='Regular'
                                    onChange={(e, f, g, h, i) =>
                                      handleTableChange(idx, e, f, g, h, i)
                                    }
                                    value={d.data.Regular}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={customClasses.select}
                                >
                                  <InputNumber
                                    disabled={checkLockout}
                                    name='Overtime'
                                    label='Overtime'
                                    onChange={(e, f, g, h, i) =>
                                      handleTableChange(idx, e, f, g, h, i)
                                    }
                                    value={d.data.Overtime}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={customClasses.select}
                                >
                                  <InputNumber
                                    disabled={checkLockout}
                                    name='Doubletime'
                                    label='Doubletime'
                                    onChange={(e, f, g, h, i) =>
                                      handleTableChange(idx, e, f, g, h, i)
                                    }
                                    value={d.data.Doubletime}
                                  />
                                </Grid>
                                {d.data.ArrowOpen ||
                                  d.data.NoWorkRecorded === 'true' ? (
                                  <Grid item xs={12}>
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Grid container>
                                          <Grid
                                            item
                                            xs={4}
                                            className={customClasses.select}
                                          >
                                            <InputNumber
                                              disabled={checkLockout}
                                              name='Vacation'
                                              label='Vacation'
                                              onChange={(e, f, g, h, i) =>
                                                handleTableChange(
                                                  idx,
                                                  e,
                                                  f,
                                                  g,
                                                  h,
                                                  i
                                                )
                                              }
                                              value={d.data.Vacation}
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={4}
                                            className={customClasses.select}
                                          >
                                            <InputSelect
                                              disabled={checkLockout}
                                              name='data.SickLeaveType'
                                              label={
                                                matches2
                                                  ? 'SL Type'
                                                  : 'Sick Leave Type'
                                              }
                                              onChange={(e, f, g, h, i) =>
                                                handleTableChange(
                                                  idx,
                                                  e,
                                                  f,
                                                  g,
                                                  h,
                                                  i
                                                )
                                              }
                                              options={[
                                                {
                                                  label: 'SL',
                                                  value: 'California'
                                                },
                                                { value: 'S1', label: 'MD/DC' },
                                                {
                                                  value: 'S2',
                                                  label: 'IBEW 26 Sick Leave'
                                                }
                                              ]}
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={4}
                                            className={customClasses.select}
                                          >
                                            <InputNumber
                                              disabled={checkLockout}
                                              name='SickLeave'
                                              label='Sick Leave'
                                              onChange={(e, f, g, h, i) =>
                                                handleTableChange(
                                                  idx,
                                                  e,
                                                  f,
                                                  g,
                                                  h,
                                                  i
                                                )
                                              }
                                              value={d.data.SickLeave}
                                            />
                                          </Grid>
                                          {matches2 ? (
                                            <Grid
                                              item
                                              xs={12}
                                              className={customClasses.select}
                                            >
                                              <InputNumber
                                                disabled={checkLockout}
                                                name='Holiday'
                                                label='Holiday'
                                                onChange={(e, f, g, h, i) =>
                                                  handleTableChange(
                                                    idx,
                                                    e,
                                                    f,
                                                    g,
                                                    h,
                                                    i
                                                  )
                                                }
                                                value={d.data.Holiday}
                                              />
                                            </Grid>
                                          ) : (
                                            <Grid
                                              item
                                              xs={12}
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                              }}
                                            >
                                              <Grid
                                                item
                                                xs={4}
                                                className={customClasses.select}
                                              >
                                                <InputNumber
                                                  disabled={checkLockout}
                                                  name='Holiday'
                                                  label='Holiday'
                                                  onChange={(e, f, g, h, i) =>
                                                    handleTableChange(
                                                      idx,
                                                      e,
                                                      f,
                                                      g,
                                                      h,
                                                      i
                                                    )
                                                  }
                                                  value={d.data.Holiday}
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                md={4}
                                                xs={12}
                                                style={{
                                                  backgroundColor:
                                                    'transparent',
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'flex-end',
                                                  alignItems: 'center',
                                                  whiteSpace: 'pre-wrap'
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: '8px -21px -8px 0px'
                                                  }}
                                                >
                                                  <InputCheckbox
                                                    checkboxes={[
                                                      { value: 'true', label: 'No work recorded.' },
                                                    ]}
                                                    LabelProps={{
                                                      label: '',
                                                      disabled: checkLockout
                                                    }}
                                                    name="NoWorkRecorded"
                                                    onChange={(e, f, g, h, i) =>
                                                      handleTableChange(idx, e, f, g, h, i)
                                                    }
                                                    value={d.data.NoWorkRecorded}
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid
                                                item
                                                md={4}
                                                xs={12}
                                                style={{
                                                  backgroundColor:
                                                    'transparent',
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'flex-end',
                                                  alignItems: 'center',
                                                  whiteSpace: 'pre-wrap'
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    margin: '8px -21px -8px 0px'
                                                  }}
                                                >
                                                  <InputCheckbox
                                                    checkboxes={[
                                                      { value: 'true', label: 'Under 8 hours verified.' },
                                                    ]}
                                                    LabelProps={{
                                                      label: '',
                                                      disabled: checkLockout
                                                    }}
                                                    name="LessThan8HoursVerified"
                                                    onChange={(e, f, g, h, i) =>
                                                      handleTableChange(idx, e, f, g, h, i)
                                                    }
                                                    value={d.data.LessThan8HoursVerified}
                                                  />
                                                </div>
                                              </Grid>
                                            </Grid>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ) : null}
                              </Grid>
                            </Grid>
                            {matches2 && d.data.ArrowOpen ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={5}
                                    style={{
                                      backgroundColor: 'transparent',
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      alignItems: 'center',
                                      whiteSpace: 'pre-wrap'
                                    }}
                                  >
                                    <div
                                      style={{ margin: '14px -21px -8px 0px' }}
                                    >
                                      <InputCheckbox
                                        checkboxes={[
                                          { value: 'true', label: 'No work recorded.' },
                                        ]}
                                        LabelProps={{
                                          label: '',
                                          disabled: checkLockout
                                        }}
                                        name="NoWorkRecorded"
                                        onChange={(e, f, g, h, i) =>
                                          handleTableChange(idx, e, f, g, h, i)
                                        }
                                        value={d.data.NoWorkRecorded}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={7}
                                    style={{
                                      backgroundColor: 'transparent',
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      alignItems: 'center',
                                      whiteSpace: 'pre-wrap'
                                    }}
                                  >
                                    <div
                                      style={{ margin: '8px -21px -8px 0px' }}
                                    >
                                      <InputCheckbox
                                        checkboxes={[
                                          { value: 'true', label: '' },
                                        ]}
                                        LabelProps={{
                                          label: '',
                                          disabled: checkLockout
                                        }}
                                        name="LessThan8HoursVerified"
                                        onChange={(e, f, g, h, i) =>
                                          handleTableChange(idx, e, f, g, h, i)
                                        }
                                        value={d.data.LessThan8HoursVerified}
                                      />
                                    </div>
                                  </Grid>
                                </Grid>{' '}
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          width: '-webkit-fill-available',
                          margin: '20px 0px 10px 0px'
                        }}
                      >
                        {!checkLockout && <div
                          className={`${iconClasses.container} ${iconClasses.trashContainer}`}
                          style={{ margin: matches ? '0px -8px' : '0px 8px' }}
                        >
                          <Button
                            disableRipple
                            style={{
                              backgoundColor: 'transparent',
                              padding: '0px',
                              minWidth: '40px',
                              maxWidth: '40px',
                              minHeight: '40px',
                              maxHeight: '40px',
                              borderRadius: '20px'
                            }}
                            onClick={() => onDelete(data, idx)}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className={iconClasses.trashIcon}
                              style={{ cursor: 'pointer' }}
                            />{' '}
                          </Button>
                        </div>}
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          width: '-webkit-fill-available',
                          margin: '10px 0px 10px 0px',
                          backgroundColor: 'transparent'
                        }}
                      >
                        <div
                          className={`${iconClasses.container} ${iconClasses.trashContainer}`}
                          style={{ margin: matches ? '0px -8px' : '0px 8px' }}
                        >
                          <Button
                            disableRipple
                            style={{
                              backgroundColor: 'transparent',
                              padding: '0px',
                              minWidth: '40px',
                              maxWidth: '40px',
                              minHeight: '40px',
                              maxHeight: '40px',
                              borderRadius: '20px'
                            }}
                            onClick={(g, h, i) =>
                              handleTableChange(
                                idx,
                                !d.data.ArrowOpen,
                                'ArrowOpen',
                                g,
                                h,
                                i
                              )
                            }
                          >
                            <FontAwesomeIcon
                              icon={
                                d.data.ArrowOpen ? faChevronDown : faChevronUp
                              }
                              className={iconClasses.trashIcon}
                            />{' '}
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );

  const getTable = () => (matches ? xsDownTable() : smUpTable());

  return data.length === 0 ? (
    <Card style={{ padding: '8px' }}>
      <i>
        There are currently no time entries for this date. Please enter time
        using the Prep Time button or the Plus button.
      </i>{' '}
    </Card>
  ) : (
    getTable()
  );
};

TimeEntryTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  checkLockout: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};
TimeEntryTable.defaultProps = {};
export default TimeEntryTable;
