import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert, Grid, useMediaQuery, Paper, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { InputAutocomplete } from '@aldridge/aldg-data-components';
import { useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { onSnapshot, setDoc, doc, collection } from 'firebase/firestore';
import { firestore, firebaseConfig } from '../../firebase';
import { _CurrentJobNumber } from '../../_Recoil/atoms';
import { UserContext } from '../../providers/UserProvider';
import { basicStyles, icons } from '../../theme';

const Distribution = (props) => {
  const classes = basicStyles();
  const { user } = useContext(UserContext);
  const { history, match } = props;
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const [record, setRecord] = useState({
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      Employee: '',
      JobNumber: CurrentJobNumber.value[0],
      JobNumberDisplay: CurrentJobNumber.display
    }
  });

  useEffect(() => {
    let mounted = true;
    if (match.params.id === 'new') return () => null;
    onSnapshot(doc(firestore, 'Distribution', match.params.id), (snap) => {
      if (snap.exists) {
        const d = snap.data();
        if (mounted) setRecord(d);
      }
    });
    return () => (mounted = false);
  }, [match.params.id]);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      changedRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        [changedRecord.data[id]] = value;
        changedRecord.data[`${id}Display`] = displayFromTypeahead
          .split('[')[0]
          .trim();
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addEmployee = (e, newRecord) => {
    try {
      const savedRecord = JSON.parse(JSON.stringify(record));
      if (match.params.id === 'new') {
        const docRef = doc(collection(firestore, 'Distribution'));
        savedRecord.id = docRef.id;
        setDoc(docRef, savedRecord);
      } else {
        setDoc(doc(firestore, 'Distribution', savedRecord.id), { merge: true });
      }
      if (newRecord) {
        setRecord({
          id: '',
          CreatedBy: '',
          CreatedDate: '',
          ModifiedBy: '',
          ModifiedDate: '',
          data: {
            Employee: '',
            JobNumber: CurrentJobNumber.value[0],
            JobNumberDisplay: CurrentJobNumber.display
          }
        });
        history.push('/DistributionList/new');
      } else {
        history.push('/DistributionList');
      }
      toast.success('Distribution saved successfully!', { autoClose: 5000 });
    } catch (err) {
      toast.error('Error saving record.', { autoClose: 5000 });
    }
  };

  const goBack = () => {
    history.push('/DistributionList');
  };

  const iconClasses = icons();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>
      <div
        style={{
          position: 'sticky',
          top: '-1px',
          left: 0,
          right: 0,
          width: '100%',
          padding: '8px',
          paddingRight: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
          zIndex: 100
        }}
      >
        <div
          className={`${iconClasses.container} ${iconClasses.greenContainer}`}
          style={{ margin: matches ? '' : '0px 12px' }}
          title='Save Record'
        >
          <Button
            onClick={addEmployee}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon icon={faSave} className={iconClasses.icon} />
          </Button>
        </div>

        <div
          className={`fa-layers fa-fw ${iconClasses.container} ${iconClasses.blueContainer}`}
          style={{ margin: matches ? '0px -8px' : '0px 12px' }}
          title='Save and New Record'
        >
          <Button
            onClick={(event) => addEmployee(event, true)}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon
              style={{ fontSize: '24px', color: 'white' }}
              icon={faSave}
            />
            <FontAwesomeIcon
              style={{ color: 'black', top: '-14px', left: '16px' }}
              icon={faSparkles}
            />
          </Button>
        </div>
        <div
          className={`${iconClasses.container} ${iconClasses.redContainer}`}
          style={{ margin: matches ? '0px 0px 0px 12px' : '0px 0px 0px 12px' }}
          title='Close Record'
        >
          <Button
            onClick={goBack}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon icon={faTimes} className={iconClasses.icon} />
          </Button>
        </div>
      </div>
      <Paper className={classes.paperWMargin}>
        <Alert
          style={{ whiteSpace: 'pre-wrap', marginBottom: '8px' }}
          severity='info'
        >
          Only Employees with Aldridge Emails can receive the PDF Distributions.
          If you need to pass it along to a customer, you may forward it to them
          after validating the received email.
        </Alert>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputAutocomplete
              firebaseConfig={firebaseConfig}
              key={record.id}
              firestoreOptions={{
                method: 'get',
                collection: 'ENT-Employees',
                valueKey: 'Email',
                where: [['Status', '==', 'A']],
                orderBy: ['LegalLastName', 'LegalFirstName']
              }}
              onChange={onChange}
              value={record.data.Employee !== '' ? [record.data.Employee] : []}
              name='Employee'
              label='Employee'
              filterDataset={(opts) =>
                opts.filter((opt) => opt.Email.indexOf('@aldridge') > -1)
              }
              optionDisplay={(opts) => {
                let display = `${opts.LegalLastName} ${opts.Suffix || ''}, ${opts.PreferredFirstName
                  } ${opts.MiddleName || ''} - [${opts.Title} - ${opts.UniversalID
                  }]`.replace(/ {2}/g, ' ');
                if (Object.keys(opts).length === 0) return '';
                if (opts.IsAldridgeEmployee === false) {
                  display += ` - [${opts.CompanyDisplay}]`;
                }
                return display;
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
Distribution.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired
};
Distribution.defaultProps = {};

export default Distribution;
