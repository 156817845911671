/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Paper, useTheme, useMediaQuery, Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { toast } from 'react-toastify';
import {
  InputAutocomplete,
  InputText,
  InputRadio,
  InputNumber
} from '@aldridge/aldg-data-components';
import { useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where
} from 'firebase/firestore';

import { firestore, firebaseConfig } from '../../firebase';
import { _CurrentJobNumber } from '../../_Recoil/atoms';
import { UserContext } from '../../providers/UserProvider';
import { basicStyles, icons } from '../../theme';

const ConfidentialityModal = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        type='button'
        className='button'
        onClick={() => setOpen(true)}
      >
        <i>Click here to review the confidentiality statement.</i>
      </button>
    </>
  );
};

const WBSEntryForm = (props) => {
  const classes = basicStyles();
  const theme = useTheme();
  const customClasses = useCallback(() => makeStyles(
    () => ({
      paper: {
        padding: theme.spacing(1.5),
        color: theme.palette.text.error,
        whiteSpace: 'nowrap',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
      },
      grid: {
        padding: '8px',
        textAlign: 'left'
      },
      radio: {
        color: 'black'
      },
      alignLeft: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '-webkit-fill-available'
      },
      alignRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '-webkit-fill-available'
      }
    }),
    { index: 1 }
  )(), []);
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useContext(UserContext);
  const { history, match } = props;
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);

  const [record, setRecord] = useState({
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      JobNumber: CurrentJobNumber.value[0],
      Picc: [],
      PayItem: '',
      EstimatedHours: '',
      EstimatedQuantity: '',
      TotalHours: '',
      CompletedHours: '',
      CompletedQuantity: '',
      Complete: 'No',
      Level0: '',
      Level1: '',
      Level2: '',
      Level3: '',
      Level4: ''
    }
  });

  const closeAllLevels = (r) => {
    getDocs(
      query(
        collection(firestore, 'PICC'),
        where('data.JobNumber', '==', CurrentJobNumber.value[0]),
        where(
          `data.Level${r.maxLevel}`,
          '==',
          r.record.data[`Level${r.maxLevel}`]
        )
      )
    ).then((docs) => {
      docs.forEach((d) => {
        const docRecord = d.data();
        docRecord.data.Complete = 'Yes';
        setDoc(d.ref, docRecord, { merge: true });
      });
    });
  };

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (match.params.id === 'new') return;
    onSnapshot(query(doc(firestore, 'PICC', match.params.id)), (snap) => {
      if (snap.exists()) {
        const d = snap.data();
        if (mounted) setRecord(d);
      }
    });
    // eslint-disable-next-line consistent-return
    return () => (mounted = false);
  }, [match.params.id]);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      changedRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addPicc = () => {
    try {
      const savedRecord = JSON.parse(JSON.stringify(record));
      if (match.params.id === 'new') {
        const RECORDID = doc(collection(firestore, 'PICC'));
        savedRecord.id = RECORDID.id;
      }
      setDoc(doc(firestore, 'PICC', savedRecord.id), savedRecord, {
        merge: true
      });

      history.push('/wbs');
      toast.success('WBS record saved successfully!', { autoClose: 5000 });
    } catch (err) {
      toast.error('Error saving record.', { autoClose: 5000 });
    }
  };

  const addNewLevel = (level) => {
    const currentRecord = { ...record };
    switch (level) {
      case '1':
        currentRecord.data.Level1 = '';
        currentRecord.data.Level2 = '';
        currentRecord.data.Level3 = '';
        currentRecord.data.Level4 = '';
        currentRecord.data.EstimatedHours = '';
        currentRecord.data.EstimatedQuantity = '';
        currentRecord.data.TotalHours = '';
        currentRecord.data.CompletedHours = '';
        currentRecord.data.CompletedQuantity = '';
        break;
      case '2':
        currentRecord.data.Level2 = '';
        currentRecord.data.Level3 = '';
        currentRecord.data.Level4 = '';
        currentRecord.data.EstimatedHours = '';
        currentRecord.data.EstimatedQuantity = '';
        currentRecord.data.TotalHours = '';
        currentRecord.data.CompletedHours = '';
        currentRecord.data.CompletedQuantity = '';
        break;
      case '3':
        currentRecord.data.Level3 = '';
        currentRecord.data.Level4 = '';
        currentRecord.data.EstimatedHours = '';
        currentRecord.data.EstimatedQuantity = '';
        currentRecord.data.TotalHours = '';
        currentRecord.data.CompletedHours = '';
        currentRecord.data.CompletedQuantity = '';
        break;
      case '4':
        currentRecord.data.Level4 = '';
        currentRecord.data.EstimatedHours = '';
        currentRecord.data.EstimatedQuantity = '';
        currentRecord.data.TotalHours = '';
        currentRecord.data.CompletedHours = '';
        currentRecord.data.CompletedQuantity = '';
        break;
      default:
        return;
    }
    currentRecord.id = '';
    currentRecord.CreatedBy = user.email;
    currentRecord.CreatedDate = new Date().toJSON();
    currentRecord.ModifiedBy = '';
    currentRecord.ModifiedDate = '';
    currentRecord.data.Complete = 'No';

    history.push(`/wbs/new`);
  };
  const goBack = () => {
    history.push('/wbs');
  };

  const completeTask = () => {
    const updatedModal = { ...modalOpen };
    let maxCloseLevel = '0';
    if (record.data.Level1 !== '') maxCloseLevel = '1';
    if (record.data.Level2 !== '') maxCloseLevel = '2';
    if (record.data.Level3 !== '') maxCloseLevel = '3';
    if (record.data.Level4 !== '') maxCloseLevel = '4';

    const updatedRecord = `Closing this WBS Task will close "${record.data[`Level${maxCloseLevel}`]
      }" and all the levels beneath it. To re-open, you will have to open each one manually. Do you wish to continue?`;
    updatedModal.message = updatedRecord;
    updatedModal.record = { maxLevel: maxCloseLevel, record };
    // setModalProps(updatedModal);
    setModalOpen(true);
  };

  const levels = ['1', '2', '3', '4'];


  const iconClasses = icons();

  return (
    <div>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' color='#3f3f3f'>
            Would you like to Complete this Task and all Sub Level Tasks?
          </DialogContentText>
          <DialogContentText id='alert-dialog-description' color='#3f3f3f'>
            (This action can only undone by an admin)
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'right' }}>

          <Button onClick={(r, { close }) => {
            closeAllLevels(r);
            close();
          }} variant='contained' color='primary' style={{ marginRight: '30px', marginBottom: '20px' }}>
            Yes
          </Button>
          <Button onClick={(r, { close }) => {
            close();
          }} variant='contained' color='error' style={{ marginRight: '50px', marginBottom: '20px' }} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          position: 'sticky',
          top: '-1px',
          left: 0,
          right: 0,
          width: '100%',
          padding: '8px',
          paddingRight: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
          zIndex: 100
        }}
      >
        <div
          className={`${iconClasses.container} ${iconClasses.greenContainer}`}
          style={{ margin: matches ? '' : '0px 12px' }}
          title='Save Record'
        >
          <Button
            onClick={addPicc}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon icon={faSave} className={iconClasses.icon} />
          </Button>
        </div>
        <div
          className={`${iconClasses.container} ${iconClasses.blueContainer}`}
          style={{ margin: matches ? '0px -8px' : '0px 12px' }}
          title='Complete Task'
        >
          <Button
            onClick={completeTask}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon icon={faCheck} className={iconClasses.icon} />
          </Button>
        </div>

        <div
          className={`${iconClasses.container} ${iconClasses.redContainer}`}
          style={{ margin: '0px 0px 0px 12px' }}
          title='Close Record'
        >
          <Button
            onClick={goBack}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon icon={faTimes} className={iconClasses.icon} />
          </Button>
        </div>
      </div>
      <Paper className={customClasses.paper}>
        <Grid container>
          <Grid item className={customClasses.grid} xs={12} md={6}>
            <Grid container direction='column'>
              <Grid
                item
                className={customClasses.grid}
                xs={12}
                style={{ paddingTop: '0', paddingLeft: '0' }}
              >
                <InputAutocomplete
                  firebaseConfig={firebaseConfig}
                  onChange={onChange}
                  firestoreOptions={{
                    method: 'get',
                    collection: 'ENT-Piccs',
                    valueKey: 'jctmstid',
                    where: [['jctdscid', '==', CurrentJobNumber.value[0]]],
                    orderBy: 'PICC'
                  }}
                  value={record.data.Picc || ''}
                  name='Picc'
                  label='Pay Item Cost Code'
                  optionDisplay={['option_PICC', ' - ', 'option_Description']}
                />
              </Grid>
              <Grid
                item
                className={customClasses.grid}
                xs={12}
                style={{ paddingTop: '0', paddingLeft: '0' }}
              >
                <InputText
                  label='Uploaded Cost Code'
                  value={record.data.PayItem || ''}
                  name='PayItem'
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={customClasses.grid} xs={12} md={6}>
            <InputText
              label='Level 0'
              value={record.data.Level0 || ''}
              onChange={onChange}
              name='Level0'
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container style={{ margin: 0 }}>
              <Grid item className={customClasses.grid} xs={12} md={6}>
                <InputText
                  label='Level 1'
                  value={record.data.Level1 || ''}
                  onChange={onChange}
                  name='Level1'
                />
              </Grid>
              <Grid item className={customClasses.grid} xs={12} md={6}>
                <InputText
                  label='Level 2'
                  value={record.data.Level2 || ''}
                  onChange={onChange}
                  name='Level2'
                />
              </Grid>
              <Grid item className={customClasses.grid} xs={12} md={6}>
                <InputText
                  label='Level 3'
                  value={record.data.Level3 || ''}
                  onChange={onChange}
                  name='Level3'
                />
              </Grid>
              <Grid item className={customClasses.grid} xs={12} md={6}>
                <InputText
                  label='Level 4'
                  value={record.data.Level4 || ''}
                  onChange={onChange}
                  name='Level4'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={customClasses.grid} md={4} xs={6}>
            <InputNumber
              label='Estimated Hours'
              value={record.data.EstimatedHours || ''}
              onChange={onChange}
              name='EstimatedHours'
            />
          </Grid>
          <Grid item className={customClasses.grid} md={4} xs={6}>
            <InputNumber
              label='Estimated Quantity'
              value={record.data.EstimatedQuantity || ''}
              onChange={onChange}
              name='EstimatedQuantity'
            />
          </Grid>
          <Grid item className={customClasses.grid} md={4} xs={12}>
            <InputNumber
              label='Total Hours'
              value={record.data.TotalHours || ''}
              onChange={onChange}
              name='TotalHours'
            />
          </Grid>
          <Grid item className={customClasses.grid} xs={12} sm={6} hidden>
            <InputNumber
              label='Completed Hours'
              value={record.data.CompletedHours || ''}
              onChange={onChange}
              name='CompletedHours'
            />
          </Grid>
          <Grid item className={customClasses.grid} xs={12} sm={6} hidden>
            <InputNumber
              label='Completed Quantity'
              value={record.data.CompletedQuantity || ''}
              onChange={onChange}
              name='CompletedQuantity'
            />
          </Grid>
          <Grid
            item
            className={`${customClasses.grid} ${customClasses.radio}`}
            xs={12}
          >
            <InputRadio
              radios={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
                { value: 'PreFab', label: 'PreFab' },
              ]}
              FormControlLabelProps={{
                label: 'Is this WBS Task Complete?',
              }}
              RadioGroupProps={{
                name: 'Complete',
                onChange,
                value: record.data.Complete || '',
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      {matches ? (
        <>
          <Grid
            style={{
              display: 'flex',
              paddingBottom: '12px',
              paddingLeft: '6px'
            }}
          >
            <Grid className={customClasses.alignLeft}>
              <Button
                variant='contained'
                className={classes.button2}
                onClick={() => addNewLevel('1')}
              >
                New Level 1
              </Button>
            </Grid>
            <Grid
              className={customClasses.alignRight}
              style={{ marginRight: '6px' }}
            >
              <Button variant='contained' onClick={() => addNewLevel('2')}>
                New Level 2
              </Button>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: 'flex',
              paddingBottom: '12px',
              paddingLeft: '6px'
            }}
          >
            <Grid className={customClasses.alignLeft}>
              <Button
                variant='contained'
                className={classes.button2}
                onClick={() => addNewLevel('3')}
              >
                New Level 3
              </Button>
            </Grid>
            <Grid
              className={customClasses.alignRight}
              style={{ marginRight: '6px' }}
            >
              <Button variant='contained' onClick={() => addNewLevel('4')}>
                New Level 4
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid align='left' style={{ marginLeft: '6px' }}>
          <div style={{ padding: '10px 0' }}>
            {levels.map((num) => (
              <Button
                key={num}
                variant='contained'
                className={classes.button2}
                onClick={() => addNewLevel(num)}
              >
                {`New Level ${num}`}
              </Button>
            ))}
          </div>
        </Grid>
      )}
    </div>
  );
};
WBSEntryForm.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired
};
WBSEntryForm.defaultProps = {};

export default WBSEntryForm;
